import React, { Component } from "react";
import "./PrivacyContent.css";

export default class PrivacyContent extends Component {
  render() {
    return (
      <div className="PrivacyBackgroundContainer">
        <div className="PrivacyBackground" />
        <div className="PrivacyContent" id="Privacy">
          <div className="Content">
            <h1>Privacy Policy</h1>
            <p>
              This privacy policy sets out how Hyper Studios Ltd uses and
              protects any information that you give Hyper Studios Ltd when you
              use this website. Hyper Studios Ltd is committed to ensuring that
              your privacy is protected. Should we ask you to provide certain
              information by which you can be identified when using this
              website, then you can be assured that it will only be used in
              accordance with this privacy statement. Hyper Studios Ltd may
              change this policy from time to time by updating this page. You
              should check this page from time to time to ensure that you are
              happy with any changes.
            </p>
            <h2>WHAT WE COLLECT</h2>
            <p>
              We may collect the following information: name contact information
              including email address demographic information such as postcode,
              preferences and interests other information relevant to customer
              surveys and/or offers For the exhaustive list of cookies we
              collect see the cookie policy page..{" "}
            </p>
            <h2>WHAT WE DO WITH THE INFORMATION WE GATHER</h2>{" "}
            <p>
              We require this information to understand your needs and provide
              you with a better service, and in particular for the following
              reasons: Internal record keeping. We may use the information to
              improve our products and services. We may periodically send
              promotional emails about new products, special offers or other
              information which we think you may find interesting using the
              email address which you have provided. From time to time, we may
              also use your information to contact you for market research
              purposes. We may contact you by email, phone, fax or mail. We may
              use the information to customise the website according to your
              interests.{" "}
            </p>
            <h2>SECURITY</h2>
            <p>
              We are committed to ensuring that your information is secure. In
              order to prevent unauthorised access or disclosure, we have put in
              place suitable physical, electronic and managerial procedures to
              safeguard and secure the information we collect online.
            </p>
            <h2>LINKS TO OTHER WEBSITES</h2>
            <p>
              Our website may contain links to other websites of interest.
              However, once you have used these links to leave our site, you
              should note that we do not have any control over that other
              website. Therefore, we cannot be responsible for the protection
              and privacy of any information which you provide whilst visiting
              such sites and such sites are not governed by this privacy
              statement. You should exercise caution and look at the privacy
              statement applicable to the website in question.
            </p>
            <h2>CONTROLLING YOUR PERSONAL INFORMATION</h2>
            <p>
              You may choose to restrict the collection or use of your personal
              information in the following ways: whenever you are asked to fill
              in a form on the website, look for the box that you can click to
              indicate that you do not want the information to be used by
              anybody for direct marketing purposes if you have previously
              agreed to us using your personal information for direct marketing
              purposes, you may change your mind at any time by writing to or
              emailing us at info@hyperstudios.co.uk We will not sell,
              distribute or lease your personal information to third parties
              unless we have your permission or are required by law to do so. We
              may use your personal information to send you promotional
              information about third parties which we think you may find
              interesting if you tell us that you wish this to happen. You may
              request details of personal information which we hold about you
              under the Data Protection Act 1998. A small fee will be payable.
              If you would like a copy of the information held on you please
              write to . If you believe that any information we are holding on
              you is incorrect or incomplete, please write to or email us as
              soon as possible, at the above address. We will promptly correct
              any information found to be incorrect.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
