import React, { Component } from "react";
import "./StatementContent.css";

export default class StatementContent extends Component {
  render() {
    return (
      <div className="PrivacyBackgroundContainer">
        <div className="PrivacyBackground" />
        <div className="PrivacyContent" id="Privacy">
          <div className="Content">
            <h2>WHO WE ARE</h2>
            <p>
              This statement is made on behalf of Hyper Studios Limited
              (“Hyper”), pursuant to section 54(1) of the Modern Slavery Act
              2015 (“MSA”). Hyper is a service and solutions provider in live
              broadcast graphics, data management, digital and social media
              applications. This statement has been prepared by us in relation
              to the MSA and relates to actions and activities during the
              financial year 6th April 2019 to the 5th April 2020.
            </p>
            <h2>OUR COMMITMENT</h2>
            <p>
              We are committed to ensuring that there is no slavery, servitude,
              forced or compulsory human labour, abuse of power over vulnerable
              individuals, human trafficking or any other form of exploitation
              as contemplated by the MSA in any part of our business or in our
              supply chain. We welcome the transparency encouraged by the MSA
              and this statement, in conjunction with our group Code of Conduct,
              sets out for the public, our clients, suppliers and employees.
            </p>
            <h2>OUR POLICIES</h2>
            <p>
              We strive for transparent business operations and performance. We
              adhere to a high set of ethics and values that guide our
              governance procedures and daily management decisions. We recognise
              that we must be a profitable business endeavouring to consistently
              operate above and beyond existing ethical and legal standards. As
              an organisation, we are committed to expanding our operations
              globally in a sustainable manner for the benefit of our clients,
              our employees, and the wider communities and environments on which
              our business impacts. The business takes a pro-active approach to
              its corporate responsibility, which is deeply rooted in its
              corporate strategy and business decisions. In accordance with our
              constant commitment to acting ethically and with integrity in all
              our business relationships, we have implemented effective systems
              and controls to ensure modern slavery is not taking place in our
              group. During 2019, we reinforced and gave effective communication
              of our relevant policies and we introduced a whistleblowing policy
              to allow people within the business to raise concerns about
              slavery and human trafficking in our supply chains. We require
              compliance on these policies from all our people and supply chain.
            </p>
            <h2>DUE DILIGENCE AND AUDITING</h2>
            <p>
              Hyper creates, processes, and fuses live data and graphics on a
              real-time basis, layering in interactive features and content to
              transform the sports viewing and fan experience. The scope of our
              supply chain includes the following activities: <br />
              - Digital platform hosting and development
              <br />
              - Customer care <br />
              - Graphic design <br />
              - The procurement of services not directly related to the creation
              of digital and broadcast solutions <br />
              <br /> We expect all of our suppliers and service providers to
              meet legal and ethical standards as a condition of doing business
              with us. We select our suppliers with care. As part of our
              supplier management process, in order to identify and mitigate the
              risk of modern slavery in our supply chain, we undertake
              preliminary checks on existing and prospective suppliers on a risk
              assessed basis. Moreover, during 2020, we will conduct auditing
              against existing clients. The outcome of these checks will allow
              us to take appropriate steps to manage any risk of modern slavery.
              Steps will include both identifying areas for improvement and
              reconsidering the relationship with any suppliers not meeting the
              required standards.
            </p>
            <p>
              Signed by: <br />
              Stuart Paterson-Browne CEO
            </p>
          </div>
        </div>
      </div>
    );
  }
}
