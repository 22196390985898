import React, { Component } from "react";
import "./PricePlanItem.css";

export default class PricePlanItem extends Component {
  render() {
    let showButton = this.props.showButton || false;
    let showMore = this.props.showMore || false;
    return (
      <div className="PricePlanItem">
        <h2>{this.props.title}</h2>
        <p className="Description">{this.props.content}</p>
        {this.props.price && <div className="Price">{this.props.price}</div>}
        {this.props.page && (
          <div className="Price">
            {this.props.page}
            <span className="SubPrice"> / page</span>
          </div>
        )}
        {this.props.monthly && (
          <div className="Price">
            {this.props.monthly}
            <span className="SubPrice"> / month</span>
          </div>
        )}
        {showButton && <button className="Secondary">Start Now</button>}
        {showMore && (
          <p>
            <span className="Link">Learn more</span>
          </p>
        )}
      </div>
    );
  }
}
