import React from "react";
import styled from "styled-components";
import UnrealIcon from "../../Images/Unreal.png";
import TrainingIcon from "../../Images/Training.png";
import VirtualIcon from "../../Images/Virtual.png";
import CreativeIcon from "../../Images/Creative.png";
import DataIcon from "../../Images/Data.png";
import GraphicsIcon from "../../Images/Graphics.png";
import { device } from "../../Device";

import anime from "animejs";
const Main = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
  padding-top: 3%;
  padding-bottom: 3%;
  flex-direction: column;
  @media ${device.phone} {
    max-width: 100%;
  }
  @media ${device.tablet} {
    max-width: 90%;
  }
  @media ${device.laptopL} {
    max-width: 50%;
  }
`;
const InnerContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  @media ${device.phone} {
    flex-direction: column;
    align-items: center;
  }
  @media ${device.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 1px -2px 20px 2px rgb(0 0 0 / 15%);
  height: 100%;
  width: 28%;
  align-items: center;
  padding: 1em;
  margin-bottom: 2em;
  justify-content: space-around;
  box-sizing: border-box;
  transition: transform 0.2s ease-in;
  background-color: #fff;
  @media ${device.phone} {
    width: 90%;
  }
  @media ${device.tablet} {
    width: 28%;
  }
  :hover {
    transform: scaleX(1.05) scaleY(1.05);
  }
`;
const Title = styled.div`
  display: flex;
  width: 100%;
  /* justify-content: center; */
  text-align: center;
  font-size: 1em;
  color: #dd1d44;
  margin-top: 2em;
  font-weight: 600;
`;

const Logo = styled.img`
  @media ${device.phone} {
    width: 30%;
  }
  @media ${device.tablet} {
    width: 60%;
  }
`;

const P = styled.p`
  display: flex;
  font-size: 0.8em;
  /* text-align: center; */
  color: #0b162f;
  line-height: 1.5em;
`;

const Button = styled.div`
  display: flex;
  background-color: ${(props) => (props.red ? "#dd1d44" : "#0b162f")};
  color: #fff;
  padding: 0.5em 1em 0.5em 1em;
  margin-right: 1em;
  font-size: 0.6em;
  align-items: center;
  justify-content: center;
  min-width: 118px;
  min-height: 30px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in;
  :hover {
    background-color: #dd1d44;
  }
`;
const Content = styled.div`
  min-height: 138px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const H2 = styled.h2`
  color: #0b162f;
  font-size: 1.8em;
  font-weight: 600;
`;
export default function Services({ showTitle }) {
  function handleClick(id) {
    let top =
      offset(document.getElementById(id)).top -
      document.getElementById("header").offsetHeight -
      0;

    let scrollCoords = {
      y: window.pageYOffset,
    };
    anime({
      targets: scrollCoords,
      y: top,
      duration: 500,
      easing: "easeInOutQuad",
      update: () => window.scroll(0, scrollCoords.y),
    });
  }
  function offset(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  return (
    <Main>
      {showTitle && <H2>SERVICES</H2>}
      <InnerContent>
        <Card>
          <Logo src={VirtualIcon}></Logo>
          <Title>VIRTUAL PRODUCTIONS​</Title>
          <Content>
            <P>
              Offering complete content and delivery packages for all types of
              Virtual Productions: pre-viz, design, build, training &
              implementation​.
            </P>
          </Content>
          <Button
            onClick={() => {
              handleClick("contact");
            }}
          >
            FIND OUT MORE
          </Button>
        </Card>
        <Card>
          <Logo src={UnrealIcon}></Logo>
          <Title>UNREAL SPECIALISTS​</Title>
          <Content>
            <P>
              Specialists in Epic's Unreal Engine, offering content creation for
              Virtual Productions, Delivery and specialised training.​
            </P>
          </Content>
          <Button
            onClick={() => {
              handleClick("contact");
            }}
          >
            FIND OUT MORE
          </Button>
        </Card>
        <Card>
          <Logo src={TrainingIcon}></Logo>
          <Title>TRAINING</Title>
          <Content>
            <P>
              We offer specialised training for Broadcast, Film, Virtual and OTT
              productions. Supplying online courses, workshops, remote and
              onsite training​.
            </P>
          </Content>
          <Button
            onClick={() => {
              handleClick("contact");
            }}
          >
            FIND OUT MORE
          </Button>
        </Card>
        <Card>
          <Logo src={GraphicsIcon}></Logo>
          <Title>GRAPHICS</Title>
          <Content>
            <P>
              ​A full range of graphics services for Outside Broadcast,
              post-production, Digital, SaaS products and bespoke graphics
              software development. ​​
            </P>
          </Content>
          <Button
            onClick={() => {
              handleClick("contact");
            }}
          >
            FIND OUT MORE
          </Button>
        </Card>
        <Card>
          <Logo src={CreativeIcon}></Logo>
          <Title>CREATIVE</Title>
          <Content>
            <P>
              Our creative team understands data and has strong experience in
              designing broadcast graphics for sport. We’re confident that we
              can work with you and surpass expectation.​​
            </P>
          </Content>
          <Button
            onClick={() => {
              handleClick("contact");
            }}
          >
            FIND OUT MORE
          </Button>
        </Card>
        <Card>
          <Logo src={DataIcon}></Logo>
          <Title>DATA MANAGEMENT​</Title>
          <Content>
            <P>
              ​Centralised Sports data management solutions. Ensuring that
              production, commentators and graphics have the same access to data
              from wherever they are. ​​
            </P>
          </Content>
          <Button
            onClick={() => {
              handleClick("contact");
            }}
          >
            FIND OUT MORE
          </Button>
        </Card>
      </InnerContent>
    </Main>
  );
}
