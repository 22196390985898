import React from "react";
import styled from "styled-components";
import { device } from "../../Device";

const Main = styled.div`
  display: flex;
  color: #fff;
  background-color: ${(props) => (props.red ? "#dd1d44" : "#0b162f")};
  padding-top: 95px;
  padding-bottom: 95px;
  justify-content: center;
  overflow: hidden;
  @media ${device.phone} {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  @media ${device.tablet} {
    padding-top: 95px;
    padding-bottom: 95px;
  }
`;

const Inner = styled.div`
  flex-direction: column;
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
`;
const InnerContent = styled.div`
  display: flex;
  justify-content: space-around;
  @media ${device.phone} {
    flex-direction: column;
  }
  @media ${device.tablet} {
    flex-direction: row;
  }
`;

const P = styled.p`
  display: flex;
  line-height: 1.7em;
  width: 40%;
  padding: 1%;
  font-weight: 100;
  @media ${device.phone} {
    width: 100%;
  }
  @media ${device.tablet} {
    width: 40%;
  }
`;

const H2 = styled.h2`
  color: ${(props) => (props.red ? "#0b162f" : "#dd1d44")};
  font-size: 1.8em;
  font-weight: 600;
`;

const Line = styled.div`
  height: 2px;
  width: 25%;
  background-color: #0b162f;
`;
export default function ServicesInfo({ red, showTitle }) {
  return (
    <Main red={red} id="services">
      <Inner>
        {showTitle && <H2 red={red}>SERVICES</H2>}
        {/* <Line /> */}
        <InnerContent>
          <P>
            HYPER offer a number of client services. As Unreal Specialists,
            HYPER offer complete content and delivery packages for all types of
            Virtual Productions, including pre-visualisation, design, build,
            training & implementation; Sports graphics and playout solutions;
            creative services, data management and consultancy. ​
          </P>
          <P>
            Unlike its competitors, HYPER is agnostic when it comes to Graphics
            providers. This allows Hyper to focus on delivering the best
            solution, without being constrained to a specific provider. . ​ ​
            Whether it is traditional on-site, managed event services, Remote
            production or SAAS Subscriptions, HYPER has the right service for
            any need.
          </P>
        </InnerContent>
      </Inner>
    </Main>
  );
}
