import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import anime from "animejs";
import { device } from "../../Device";

const Main = styled.div`
  display: flex;
  background-color: ${(props) => (props.white ? "#fff" : "")};
  color: ${(props) => (props.white ? "#0e172c" : "#fff")};
  height: 50px;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  position: fixed;
  top: 0;
  box-sizing: border-box;
  z-index: 100;
  transition: background-color 0.3s ease-in;
  width: 100%;
  box-shadow: ${(props) =>
    props.white ? "1px -2px 20px 2px rgb(0 0 0 / 15%)" : ""};
  padding-top: 0.5em;
  padding-bottom: 0.5em;
`;

const HyperLogo = styled.img`
  height: 50%;
  cursor: pointer;
`;
const Nav = styled.div`
  display: flex;
  margin-left: auto;
  @media ${device.phone} {
    display: none;
  }
  @media ${device.tablet} {
    display: flex;
  }
`;

const Link = styled.div`
  display: flex;
  margin-left: 4em;
  font-weight: 600;
  font-size: 0.7em;
  cursor: pointer;

  transition: color 0.2s ease-in;
  :hover {
    color: #dd1d44;
  }
`;
export default function Header() {
  const headerRef = useRef();
  const [whiteHeader, setWhiteHeader] = useState(false);
  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onscroll);
    };
  }, []);

  function onScroll(ev) {
    var y = window.scrollY;
    if (y <= 100) {
      setWhiteHeader(false);
    } else {
      setWhiteHeader(true);
    }
  }

  function handleClick(id) {
    let top =
      offset(document.getElementById(id)).top -
      headerRef.current.offsetHeight -
      0;

    let scrollCoords = {
      y: window.pageYOffset,
    };
    anime({
      targets: scrollCoords,
      y: top,
      duration: 500,
      easing: "easeInOutQuad",
      update: () => window.scroll(0, scrollCoords.y),
    });
  }
  function offset(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  return (
    <Main white={whiteHeader} ref={headerRef} id="header">
      <HyperLogo
        src="Images/HyperLogo.png"
        alt="Company Logo"
        onClick={() => handleClick("main")}
      />
      <Nav>
        <Link onClick={() => handleClick("about")}>About</Link>
        <Link onClick={() => handleClick("products")}>Products</Link>
        <Link onClick={() => handleClick("services")}>Services</Link>
        <Link onClick={() => handleClick("integrations")}>Integrations</Link>
        <Link onClick={() => handleClick("clients")}>Clients</Link>
        <Link onClick={() => handleClick("contact")}>Contact</Link>
      </Nav>
    </Main>
  );
}
