import React, { Component } from "react";
import "./SocialContent.css";
import InstagramBlock from "./InstagramBlock/InstagramBlock";
import TwitterBlock from "./TwitterBlock/TwitterBlock";
import Instagram from "../../Core/SVG/Instagram";

export default class SocialContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instagram: [],
      tweets: [],
    };
  }
  componentDidMount() {
    fetch("/insta", {
      method: "GET",
    })
      .then(function(response) {
        console.log(response);
        if (
          response.status === 200 &&
          response.headers.get("content-type").indexOf("application/json") > -1
        )
          return response.json();
        else return { error: "there was an error with response" };
      })
      .then((data) => {
        if (data.error) {
          //handle error
          console.log(data.error);
        } else {
          this.setState({
            instagram: data,
          });
        }
      });

    fetch("/twitter", {
      method: "GET",
    })
      .then(function(response) {
        console.log(response);
        if (
          response.status === 200 &&
          response.headers.get("content-type").indexOf("application/json") > -1
        )
          return response.json();
        else return { error: "there was an error with response" };
      })
      .then((data) => {
        if (data.error) {
          //handle error
          console.log(data.error);
        } else {
          this.setState({
            tweets: data,
          });
        }
      });
  }

  render() {
    let colour2 = "#db0a41";

    if (this.props.altColour) {
      colour2 = "#0b162f";
    }

    console.log(this.state.instagram[0]);

    return (
      <div className="SocialContent">
        <h1 id="Social">Social</h1>
        {/* <div className="Content Insta">
          <div className="InstagramAvatarContainer">
            <a href="https://www.instagram.com/hyper_studios_social">
              <div className="InstagramAvatar">
                <div
                  className="InstagramProfileImage"
                  style={{
                    backgroundImage: `url(${
                      this.state.instagram.length > 0
                        ? this.state.instagram[0].user.profile_picture
                        : ""
                    }`
                  }}
                />
                hyper_studios_social
              </div>
            </a>
          </div>
          <div className="InstaPosts">
            {this.state.instagram.map(item => {
              return (
                <InstagramBlock
                  src={item.images.standard_resolution.url}
                  text={item.caption.text}
                  link={item.link}
                />
              );
            })}
          </div>
        </div> */}
        <div className="Content">
          {this.state.tweets.map((item) => {
            return <TwitterBlock comment={item.text} />;
          })}
        </div>
      </div>
    );
  }
}
