import React, { Component } from "react";
import "./Page3.css";
import LeagueStandings from "./BasketballGraphics/LeagueStandings/LeagueStandings";

export default class Page3 extends Component {
  playLeague() {
    document
      .querySelector(".LeagueTable .OrangeContent")
      .classList.remove("Active");
    document
      .querySelector(".LeagueTable .OrangeContent")
      .classList.remove("Loop");
    document
      .querySelector(".LeagueTable .MainContent")
      .classList.remove("Active");
    setTimeout(function() {
      document
        .querySelector(".LeagueTable .OrangeContent")
        .classList.add("Active");
    }, 1000);
    setTimeout(function() {
      document
        .querySelector(".LeagueTable .OrangeContent")
        .classList.add("Loop");
      document
        .querySelector(".LeagueTable  .MainContent")
        .classList.add("Active");
    }, 2000);
  }
  render() {
    return (
      <div className="Page3 Page">
        <div className="PageDetails">
          <div className="PageNum">2. </div>
          <h1>League Standings</h1>
        </div>
        <div className="GraphicPlayer">
          <LeagueStandings />
        </div>
        <button onClick={() => this.playLeague()}>Replay</button>
      </div>
    );
  }
}
