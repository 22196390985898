import React from "react";
import styled from "styled-components";
import { device } from "../../Device";

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;

  padding-top: 3%;
  padding-bottom: 3%;
  @media ${device.phone} {
    text-align: center;
    max-width: 90%;
  }
  @media ${device.tablet} {
    text-align: left;
    max-width: 50%;
  }
`;

const H1 = styled.h1`
  font-size: 40px;
  color: #db1e44;
  margin-top: 0ex;
`;

const P = styled.p`
  display: flex;
  justify-content: center;
  text-align: left;
  font-size: 1em;
  line-height: 1.9em;
`;
const HyperLogo = styled.img`
  height: 30px;
`;
export default function About() {
  return (
    <Main id="about">
      <H1>
        We are <HyperLogo src="Images/HyperLogo.png" alt="Company Logo" />
      </H1>
      <P>
        HYPER Studios are a creative studio providing innovative, real-time,
        cloud enabled graphic products, solutions and related services to a wide
        range of industries, including: Broadcast, Production, Streaming, OTT
        services, Events, Sporting Bodies, Stadiums, Clubs, Digital Display
        Systems and Advertising.
      </P>
      <P>
        Founded in 2019 to create choice in an aging broadcast graphics market,
        HYPER’s products were quickly taken up by major broadcasters such as
        SKY, IMG and Premier Sports. These forward-thinking early adopters could
        see the value in HYPER’s cloud based, work-from-anywhere approach and
        have continued to integrate HYPER deeply into their workflows.
      </P>
    </Main>
  );
}
