import React, { Component } from "react";
import "./InstagramBlock.css";

export default class InstagramBlock extends Component {
  render() {
    return (
      <div className="InstagramBlock">
        <a href={this.props.link}>
          <div className="InstagramOverlay">{this.props.text}</div>
          <div className="InstagramImage">
            <img src={this.props.src} alt="Instagram" />
          </div>
        </a>
        {/* <div>{this.props.text}</div> */}
      </div>
    );
  }
}
