import React from "react";
import styled from "styled-components";
import Hero1 from "../../Images/Hero1.png";
import anime from "animejs";
import { device } from "../../Device";
const Main = styled.div`
  display: flex;
  background-color: #0e172c;
  width: 100vw;
  height: 100vh;
  background-size: cover;
`;
const Video = styled.video`
  opacity: 0.2;
  width: 100vw;
  object-fit: fill;
`;

const Content = styled.div`
  display: flex;
  color: #fff;
  position: absolute;
  bottom: 0px;
  padding-bottom: 5%;
  flex-direction: column;

  @media ${device.phone} {
    max-width: 100%;
    padding: 6%;
  }
  @media ${device.tablet} {
    max-width: 50%;
    padding-left: 10%;
  }
`;

const H1 = styled.h1`
  color: #dd1d44;
  margin-bottom: 0;

  @media ${device.phone} {
    font-size: 2em;
  }
  @media ${device.tablet} {
    font-size: 3em;
  }
`;

const H2 = styled.h2`
  font-size: 2em;
  margin: 0;
`;

const Buttons = styled.div`
  display: flex;
  @media ${device.phone} {
    flex-direction: column;
  }
  @media ${device.tablet} {
    flex-direction: row;
  }
`;

const Button = styled.div`
  display: flex;
  background-color: ${(props) => (props.red ? "#dd1d44" : "#fff")};
  color: ${(props) => (props.red ? "#fff" : "#dd1d44")};
  padding: 0.5em 1em 0.5em 1em;

  font-size: 0.6em;
  align-items: center;
  justify-content: center;
  min-width: 118px;
  min-height: 30px;
  font-weight: 600;
  cursor: pointer;
  @media ${device.phone} {
    margin-top: 1em;
    margin-right: 0;
  }
  @media ${device.tablet} {
    margin-right: 1em;
    margin-top: 0;
  }
`;

const P = styled.p`
  padding-top: 5%;
  padding-bottom: 5%;
  max-width: 80%;
`;
export default function Hero() {
  function handleClick(id) {
    let top =
      offset(document.getElementById(id)).top -
      document.getElementById("header").offsetHeight -
      0;

    let scrollCoords = {
      y: window.pageYOffset,
    };
    anime({
      targets: scrollCoords,
      y: top,
      duration: 500,
      easing: "easeInOutQuad",
      update: () => window.scroll(0, scrollCoords.y),
    });
  }
  function offset(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  return (
    <Main>
      <Video autoPlay muted playsinline loop poster={Hero1}>
        <source src="Videos/HYPER_WebBKGD.mp4" type="video/mp4" />
      </Video>
      {/* <Image src={Hero1} /> */}
      <Content>
        <H1>NEXT-GEN GRAPHICS</H1>
        <H2>Real-time Graphics & Data management in the Cloud​</H2>
        <P>
          HYPER powers your productions with a suite of cost-effective,
          real-time graphics and data management tools in an out-of-the-box
          Cloud solution.
        </P>
        <Buttons>
          <Button red onClick={() => handleClick("contact")}>
            REQUEST A DEMO
          </Button>
          <Button onClick={() => handleClick("products")}>PRODUCTS</Button>
        </Buttons>
      </Content>
    </Main>
  );
}
