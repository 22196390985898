import React from "react";
import styled from "styled-components";
import { device } from "../../Device";

const Main = styled.div`
  display: flex;
  color: #fff;
  background-color: ${(props) => (props.red ? "#dd1d44" : "#0b162f")};

  justify-content: center;
  overflow: hidden;
  @media ${device.phone} {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  @media ${device.tablet} {
    padding-top: 95px;
    padding-bottom: 95px;
  }
`;

const Inner = styled.div`
  flex-direction: column;
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
`;
const InnerContent = styled.div`
  display: flex;
  justify-content: space-around;
  @media ${device.phone} {
    flex-direction: column;
  }
  @media ${device.tablet} {
    flex-direction: row;
  }
`;

const P = styled.p`
  display: flex;
  line-height: 1.7em;

  padding: 1%;
  font-weight: 100;
  @media ${device.phone} {
    width: 100%;
  }
  @media ${device.tablet} {
    width: 40%;
  }
`;

const H2 = styled.h2`
  color: #0b162f;
  font-size: 1.8em;
  font-weight: 600;
`;

const Line = styled.div`
  height: 2px;
  width: 25%;
  background-color: #0b162f;
`;
export default function ProductInfo({ red }) {
  return (
    <Main red={red} id="products">
      <Inner>
        {red && <H2 red={red}>PRODUCTS</H2>}
        {/* <Line /> */}
        <InnerContent>
          <P>
            HYPER has a suite of affordable, innovative, scalable in-house
            products that easily fit into any workflow. From traditional
            on-premise SDI installs to NDITM and cloud based HTML5 /WebGL ‘URL’
            based graphics. HYPER products seamlessly integrate into most
            production platforms, be it referenced Fill and Key signals into a
            traditional switcher, or NDITM and HTML5 inputs to VMIX and OBS etc.
          </P>
          <P>
            At the heart of the HYPER ecosystem is HYPER Engine, HYPERS’s own in
            house graphics renderer. This Engine is in daily use by Sky, Premier
            Sports and IMG to name a few. Graphics are fully customisable to
            match your brand or you can choose from a selection of pre built
            Hyper designed themes.
          </P>
        </InnerContent>
      </Inner>
    </Main>
  );
}
