import React from "react";
import styled from "styled-components";
import { device } from "../../Device";
const H2 = styled.h2`
  font-weight: 600;
  z-index: 1;
  @media ${device.phone} {
    font-size: 1.4em;
  }
  @media ${device.tablet} {
    font-size: 1.8em;
  }
`;

const Header = styled.div`
  background-color: #0b162f;
  color: #fff;
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

const Angle = styled.div`
    background-color: #dd1d44;
    width: 66px;
    height: 1000px;
    position: absolute;
    top: -214px;
    transform: rotate(26deg);
    left: 25%;
}
`;
export default function IntegrationHeader({ title }) {
  return (
    <Header id="integrations">
      <Angle />
      <H2>{title}</H2>
    </Header>
  );
}
