import React from "react";
import styled from "styled-components";
import NeverNo from "../../Images/NeverNo.png";
import LiveLike from "../../Images/LiveLike.png";
import { device } from "../../Device";

const Main = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
  padding-top: 3%;
  padding-bottom: 3%;
  flex-direction: column;
  @media ${device.phone} {
    max-width: 100%;
  }
  @media ${device.tablet} {
    max-width: 90%;
  }
  @media ${device.laptopL} {
    max-width: 50%;
  }
`;
const InnerContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  @media ${device.phone} {
    flex-direction: column;
    align-items: center;
  }
  @media ${device.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 1px -2px 20px 2px rgb(0 0 0 / 15%);
  align-items: center;
  padding: 1em;
  margin-bottom: 2em;
  justify-content: space-around;
  box-sizing: border-box;
  transition: transform 0.2s ease-in;
  background-color: #fff;
  @media ${device.phone} {
    width: 90%;
  }
  @media ${device.tablet} {
    width: 28%;
  }
  :hover {
    transform: scaleX(1.05) scaleY(1.05);
  }
`;
const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  font-size: 1em;
  color: #dd1d44;
  margin-top: 2em;
  font-weight: 600;
  display: none;
`;

const Logo = styled.img`
  width: 60%;
`;

const P = styled.p`
  display: flex;
  font-size: 0.8em;
  color: #0b162f;
  line-height: 1.5em;
`;

const Button = styled.div`
  display: flex;
  background-color: ${(props) => (props.red ? "#dd1d44" : "#0b162f")};
  color: #fff;
  padding: 0.5em 1em 0.5em 1em;
  margin-right: 1em;
  font-size: 0.6em;
  align-items: center;
  justify-content: center;
  min-width: 118px;
  min-height: 30px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in;
  :hover {
    background-color: #dd1d44;
  }
`;
const Content = styled.div`
  min-height: 138px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export default function SocialIntegrations({ showTitle }) {
  return (
    <Main>
      <InnerContent>
        <Card>
          <Logo src={NeverNo}></Logo>
          <Title>NEVER.NO</Title>
          <Content>
            <P>
              Engage your audience with Bee-On by Never.no. Never.no are
              Audience Engagement Specialists.​
            </P>
          </Content>
          <a
            target="_blank"
            href="https://never.no/portfolio/content-moderation-engine"
          >
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={LiveLike}></Logo>
          <Title>LIVELIKE</Title>
          <Content>
            <P>
              LiveLike is an audience engagement platform that allows
              broadcasters to transform streaming into fan-driven interactive
              experiences.​
            </P>
          </Content>
          <a target="_blank" href="https://livelike.com">
            <Button>MORE INFO</Button>
          </a>
        </Card>
      </InnerContent>
    </Main>
  );
}
