import React, { Component } from "react";
import Footer from "../Components/Footer/Footer";
import Hero from "../Components/Hero/Hero";
import Header from "../Components/Header/Header";
import PrivacyContent from "../Components/PrivacyContent/PrivacyContent";

export default class Privacy extends Component {
  render() {
    return (
      <div>
        <Header colour={"3"} showLinks={false} />
        <Hero colour={"3"} text="Your privacy matters" />
        <PrivacyContent />
        <Footer altColour={true} route={"PRIVACY"} />
      </div>
    );
  }
}
