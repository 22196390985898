import React, { Component } from "react";
import "./AboutContent.css";

export default class AboutContent extends Component {
  render() {
    return (
      <div className="AboutContent" id="About">
        <h1>About us</h1>
        <p class="HyperColour1">
          Hyper is a new startup, providing technology solutions for all your
          broadcast graphics needs. We provide a friendly service using talented
          people, who are always happy to help and enjoy the most challenging of
          situations.
        </p>
        <div className="AboutSplitter" />
        <div className="AboutUsPara">
          {/* <p className="LeftAbout">
            <span className="H">Hyper</span> was founded in 2019 by Ashley
            Williams and Stuart Paterson-Browne. Stuart and Ashley have 25 years
            combined experience in the broadcast Industry. Degree educated
            software developers, Stuart and Ashley have developed integrated
            Scoring, timing and graphics solutions, for high profile global
            sporting events including The Ryder Cup, The Open Championship,
            European Tour, Euroleague Basketball, NBA Global games and FIA WTCC
            amongst others.
          </p> */}
          <p className="RightAbout">
            <span className="H">Hyper</span> was founded to create choice in an
            aging market, with a passion for technology, innovation and design.
            Our philosophy is to collaborate and treat stakeholders as partners
            with shared goals, not just as clients. When it comes to Graphics
            platforms, Hyper is agnostic. This allows Hyper to focus on
            delivering the best solution, without being constrained to a
            specific provider. Hyper currently has integrations with ChyronHego,
            Vizrt, Ross Video, RT Software and Brainstorm. Where budgets are
            tight, Hyper can offer its own, entry level graphics solution.
            Whatever your need, Hyper can help.
          </p>
        </div>
        <div className="AboutSplitter" />
      </div>
    );
  }
}
