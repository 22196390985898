import React, { Component } from "react";
import Footer from "../Components/Footer/Footer";
import Hero from "../Components/Hero/Hero";
import HeroButtons from "../Components/Hero/HeroButtons";
import PricePlan from "../Components/PricePlan/PricePlan";
import Questions from "../Components/Questions/Questions";
import Features from "../Components/Features/Features";
import HeaderCreate from "../Components/Header/Header_Create";

export default class Create extends Component {
  render() {
    return (
      <div>
        <HeaderCreate colour={"3"} showLinks={true} />
        <Hero colour={"3"} text="Need help creating your new website..." />
        <HeroButtons />
        <PricePlan />
        <Features />
        <Questions />
        <Footer altColour={true} />
      </div>
    );
  }
}
