import React, { Component } from "react";
import "./Stage1.css";
export default class Stage1 extends Component {
  render() {
    return (
      <div className="Stage1">
        <h1>Project Details</h1>
        <div className="Form">
          <div className="Item">
            <label>Project Name</label>
            <input type="text" />
          </div>
          <div className="Item">
            <label>Your Name</label>
            <input type="text" />
          </div>

          <div className="Item">
            <label>Email</label>
            <input type="text" />
          </div>
          <div className="Item Top">
            <label>Tell us about your ideas</label>
            <textarea type="text" rows="5" />
          </div>
          <button
            className="Disabled"
            onClick={this.props.actionOnStageComplete}
          >
            Stage 2
          </button>
        </div>
      </div>
    );
  }
}
