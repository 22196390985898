import React, { Component } from "react";
import Footer from "../Components/Footer/Footer";
import Hero from "../Components/Hero/Hero";
import Header from "../Components/Header/Header";
import CookieContent from "../Components/CookieContent/CookieContent";

export default class CookiePolicy extends Component {
  render() {
    return (
      <div>
        <Header colour={"3"} showLinks={false} />
        <Hero colour={"3"} text="Cookie free zone" />
        <CookieContent />
        <Footer altColour={true} route={"COOKIE"} />
      </div>
    );
  }
}
