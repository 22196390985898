import React, { Component } from "react";
import JSONPretty from "react-json-pretty";
import styled from "styled-components";
const example1 = {
  action: "RENDER",
  filename: "LIVERPOOLvARSENAL",
  scene: "SKY MATCHID",
  data: [
    {
      name: "HOME_TEAM-1",
      value: {
        text: "LIVERPOOL",
      },
    },
    {
      name: "HOME_TEAM-2",
      value: {
        text: "LIVERPOOL",
      },
    },
    {
      name: "HOME_BADGE-1",
      value: {
        image: "http://localhost:6060/uploads/WSM/Badges%5CLiverpool.png",
      },
    },
    {
      name: "AWAY_TEAM-1",
      value: {
        text: "ARSENAL",
      },
    },
    {
      name: "AWAY_TEAM-2",
      value: {
        text: "ARSENAL",
      },
    },
    {
      name: "AWAY BADGE",
      value: {
        image: "http://localhost:6060/uploads/WSM/Badges%5CArsenal.png",
      },
    },
    {
      name: "STADIUM TEXT",
      value: {
        text: "Anfield",
      },
    },
    {
      name: "KICKOFF",
      value: {
        text: "Kick-Off 3pm",
      },
    },
  ],
};

const Main = styled.div`
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  background-color: white;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em;
  padding-left: 0.5em;
  height: 40px;
  border-bottom: 1px solid #eee;
  width: 100%;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.16);
`;

const Logo = styled.img`
  height: 50%;
`;

const Content = styled.div`
  display: flex;
  min-height: 2000px;
`;

const Nav = styled.div`
  display: flex;
  min-width: 300px;
  max-width: 300px;
  border-right: 1px solid #eee;
  min-height: 100vh;
  flex-direction: column;
  background-color: #dee2e6;
`;
const Details = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
const Center = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  width: 60%;
`;
const Examples = styled.div`
  display: flex;
  width: 40%;
  background-color: #d0d3d6;
  height: 2000px;
`;
const Link = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 0.5em;
  width: 100%;
  :hover {
    background-color: #d0d3d6;
  }
`;

const Example = styled.div`
  display: flex;
  position: absolute;
  background-color: #fff;
  border-radius: 4px;
  font-size: 0.8em;
  margin: 0.5em;
  padding: 0.5em;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  top: 654px;
`;
const Parameter = styled.div`
  display: flex;
  border-top: 1px solid #d1d1d2;
  padding: 0.5em;
`;
const Type = styled.div`
  display: flex;
  min-width: 200px;
  color: #e03859;
`;
const Description = styled.div`
  display: flex;
  flex-direction: column;
`;
export default class RenderDocs extends Component {
  render() {
    return (
      <Main>
        <Header>
          <Logo src="Images/HyperLogo.png" alt="Company Logo" />
        </Header>
        <Content>
          {/* <Nav>
            <Link>Introduction</Link>
            <Link>Authentication</Link>
          </Nav> */}
          <Details>
            <Center>
              <h1>Introduction</h1>
              <p>This is the REST API to control Hyper Render engines.</p>
              <h1>Authentication</h1>
              <p>
                Each request made to the API must contain an API key which is
                provided when you subscribe to the service.
                <br />
                The API key is sent by appending it to the enpoint eg.
                https://space.hyperstudios.live/{"{"}apikey{"}"}
              </p>
              <h1>Message Format</h1>
              <p>The messages sent are in JSON.</p>
              <h1>API Methods</h1>
              <h2>Render</h2>
              <h3>
                POST https://space.hyperstudios.live/{"{"}apikey{"}"}
              </h3>
              The render message is sent to tell the engine which graphic you
              want to render and with what data.
              <h3>Parameters</h3>
              <Parameter>
                <Type>action *</Type>
                <Description>Render is the only type supported.</Description>
              </Parameter>
              <Parameter>
                <Type>filename *</Type>
                <Description>
                  The name of the file. Must exlude file extension eg. .mov this
                  will be added by the renderer.
                </Description>
              </Parameter>
              <Parameter>
                <Type>scene *</Type>
                <Description>
                  The name of the scene (type of graphic) you want to render.
                  The designer will give you a list of scenes available.
                </Description>
              </Parameter>
              <Parameter>
                <Type>data</Type>
                <Description>
                  Data is an array of graphical elements you wish to update with
                  custom data.
                  <p>
                    Each item in the array is an object using the below format:
                  </p>
                  <Parameter>
                    <Type>name</Type>
                    <Description>
                      The name of the item you wish to update (provided by the
                      person who designed the template)
                    </Description>
                  </Parameter>
                  <Parameter>
                    <Type>value</Type>
                    <Description>
                      Contains a list of variables to be updated, one or many
                      items can be updated such as position, size, image, text
                      <p>
                        <b>text</b>: Updates the text of the node
                        <br />
                        <b>image</b>: Updates the image by providing a url to
                        the new image.
                      </p>
                    </Description>
                  </Parameter>
                </Description>
              </Parameter>
            </Center>
            <Examples>
              <Example>
                <h2>Example</h2>
                <JSONPretty id="json-pretty" data={example1}></JSONPretty>
              </Example>
            </Examples>
          </Details>
        </Content>
      </Main>
    );
  }
}
