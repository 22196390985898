import React from "react";
import styled from "styled-components";
import OPTA from "../../Images/OPTA.png";
import FIBA from "../../Images/FIBA.png";
import GENIUS from "../../Images/Genius.png";
import ShotLink from "../../Images/ShotLink.png";
import NBA from "../../Images/NBA.png";
import StatsPerform from "../../Images/StatsPerform.png";
import SwissTiming from "../../Images/SwissTiming.png";
import Bodet from "../../Images/Bodet.png";
import Stramatel from "../../Images/Stramatel.png";
import { device } from "../../Device";

const Main = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
  padding-top: 3%;
  padding-bottom: 3%;
  flex-direction: column;
  @media ${device.phone} {
    max-width: 100%;
  }
  @media ${device.tablet} {
    max-width: 90%;
  }
  @media ${device.laptopL} {
    max-width: 50%;
  }
`;
const InnerContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  @media ${device.phone} {
    flex-direction: column;
    align-items: center;
  }
  @media ${device.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 1px -2px 20px 2px rgb(0 0 0 / 15%);
  height: 100%;
  align-items: center;
  padding: 1em;
  margin-bottom: 2em;
  justify-content: space-around;
  box-sizing: border-box;
  transition: transform 0.2s ease-in;
  background-color: #fff;
  @media ${device.phone} {
    width: 90%;
  }
  @media ${device.tablet} {
    width: 28%;
  }
  :hover {
    transform: scaleX(1.05) scaleY(1.05);
  }
`;
const Title = styled.div`
  display: flex;
  width: 100%;
  display: none;

  font-size: 1em;
  color: #dd1d44;
  margin-top: 2em;
  font-weight: 600;
`;

const Logo = styled.img`
  width: auto;
  height: 40px;
`;

const P = styled.p`
  display: flex;
  font-size: 0.8em;
  line-height: 1.5em;
  color: #0b162f;
`;

const Button = styled.div`
  display: flex;
  background-color: ${(props) => (props.red ? "#dd1d44" : "#0b162f")};
  color: #fff;
  padding: 0.5em 1em 0.5em 1em;
  margin-right: 1em;
  font-size: 0.6em;
  align-items: center;
  justify-content: center;
  min-width: 118px;
  min-height: 30px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in;
  :hover {
    background-color: #dd1d44;
  }
`;
const Content = styled.div`
  min-height: 138px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export default function SportsIntegrations({ showTitle }) {
  return (
    <Main>
      <InnerContent>
        <Card>
          <Logo src={OPTA}></Logo>
          <Title>OPTA</Title>
          <Content>
            <P>
              World leaders in sports data Detailed sports data from Stats
              Perform to bring your content and coverage to life
            </P>
          </Content>
          <a target="_blank" href="https://www.optasports.com/">
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={FIBA}></Logo>
          <Title>FIBA LIVE STATS​</Title>
          <Content>
            <P>
              FIBA Live stats has been developed off the back of extensive
              research about how fans and leagues consume basketball statistics​
            </P>
          </Content>
          <a target="_blank" href="http://www.fibaorganizer.com/">
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={GENIUS}></Logo>
          <Title>GENIUS SPORTS​​</Title>
          <Content>
            <P>
              GENIUS SPORTS build innovative technology that unlocks the true
              value of official data and video, connecting the worlds of sport,
              betting and media.​
            </P>
          </Content>
          <a target="_blank" href="https://geniussports.com/sports/">
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={ShotLink}></Logo>
          <Title>SHOTLINK</Title>
          <Content>
            <P>
              The ShotLink System is a revolutionary platform for collecting and
              disseminating scoring and statistical data on every shot by every
              player in real-time.​​
            </P>
          </Content>
          <a target="_blank" href="http://www.shotlink.com/">
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={NBA}></Logo>
          <Title>NBA GLOBAL STATS SYSTEM (NGSS)</Title>
          <Content>
            <P>
              Live API data integration with the NBA and their proprietary NBA
              Global Statistical System (NGSS)​
            </P>
          </Content>
          <a target="_blank" href="https://www.nba.com/news">
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={StatsPerform}></Logo>
          <Title>STATS PERFORM​</Title>
          <Content>
            <P>
              Sports predictions and insights​ Stats Perform unlocks what was
              once immeasurable in sport by combining trusted sports data and
              storytelling capabilities with their AI innovation centre.​
            </P>
          </Content>
          <a target="_blank" href="https://www.statsperform.com">
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={SwissTiming}></Logo>
          <Title>SWISS TIMING​</Title>
          <Content>
            <P>
              Timekeeping and scoring in basketball – especially with the
              importance of statistics in the sport – requires extensive Swiss
              Timing support​.
            </P>
          </Content>
          <a
            target="_blank"
            href="https://www.swisstiming.com/services/sport-services/scoreboards"
          >
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={Bodet}></Logo>
          <Title>BODET</Title>
          <Content>
            <P>
              For over forty years, Bodet Sport has been designing and building
              sports display solutions for both communities and teams​.
            </P>
          </Content>
          <a
            target="_blank"
            href="https://www.bodet-sport.com/scoreboards.html"
          >
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={Stramatel}></Logo>
          <Title>STRAMATEL</Title>
          <Content>
            <P>
              Since 1981, Stramatel has been designing and manufacturing
              electronic scoreboards and LED display systems for any sport
              disciplines​.
            </P>
          </Content>
          <a target="_blank" href="https://www.stramatel.com/en/">
            <Button>MORE INFO</Button>
          </a>
        </Card>
      </InnerContent>
    </Main>
  );
}
