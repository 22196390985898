import React from "react";
import styled from "styled-components";
import ClientsImage from "../../Images/Clients.png";
import { device } from "../../Device";
const Main = styled.div`
  display: flex;
  background-color: #0b162f;
  padding-top: 5%;
  padding-bottom: 5%;
  justify-content: center;
  overflow: hidden;
`;

const Inner = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
`;
const Image = styled.img`
  @media ${device.phone} {
    width: 90%;
  }
  @media ${device.tablet} {
    width: 100%;
  }
`;

export default function Clients() {
  return (
    <Main id="clients">
      <Inner>
        <Image src={ClientsImage} />
      </Inner>
    </Main>
  );
}
