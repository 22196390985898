import React, { Component } from "react";
import "./SubContent.css";

export default class SubContent extends Component {
  render() {
    return (
      <div className="SubContent">
        <div className="Inner">
          <h2 className="Title">{this.props.title}</h2>
          <p>{this.props.content}</p>
        </div>
      </div>
    );
  }
}
