import React, { Component } from "react";
import Loading from "../Loading/Loading";

import "./ContactContent.css";

export default class ContactContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
      loading: false,
      nameError: false,
      emailError: false,
      messageError: false,
    };
  }

  handleFormSubmit() {
    let body = {
      name: document.getElementById("name").value,
      email: document.getElementById("email").value,
      message: document.getElementById("message").value,
    };
    let error = false;
    if (!body.name || body.name === "") {
      error = true;
      this.setState({
        nameError: true,
      });
    } else {
      this.setState({
        nameError: false,
      });
    }
    if (!body.email && body.email === "") {
      error = true;
      this.setState({
        emailError: true,
      });
    } else {
      this.setState({
        emailError: false,
      });
    }
    if (!body.message && body.message === "") {
      error = true;
      this.setState({
        messageError: true,
      });
    } else {
      this.setState({
        emailError: false,
      });
    }
    if (error) {
      return;
    }
    this.setState({
      loading: true,
      nameError: false,
      emailError: false,
      messageError: false,
    });

    fetch("/contact", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then(() => {
      setTimeout(() => this.setState({ sent: true, loading: false }), 4000);
    });
  }
  render() {
    return (
      <div className="ContactContent" id="Contact">
        <h1>Say Hi..</h1>

        {this.state.loading && <Loading />}
        {this.state.sent === false && this.state.loading === false && (
          <div>
            <a class="EmailAddress" href="mailto:info@hyperstudios.co.uk">
              info@hyperstudios.co.uk
            </a>
          </div>
        )}
      </div>
    );
  }
}
