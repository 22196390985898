import React, { Component } from "react";
import "./LeagueStandings.css";

export default class LeagueStandings extends Component {
  componentDidMount() {
    setTimeout(function() {
      document
        .querySelector(".LeagueTable .OrangeContent")
        .classList.add("Active");
    }, 1000);
    setTimeout(function() {
      document
        .querySelector(".LeagueTable .OrangeContent")
        .classList.add("Loop");
      document
        .querySelector(".LeagueTable  .MainContent")
        .classList.add("Active");
    }, 2000);
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <div class="LeagueTable">
          <div class="Background" id="LeagueTableGraphic">
            <div class="OrangeContent">
              <img
                class="ELLogo"
                src="/BasketballStyle/Images/EL_Vertical_1C_RGB.png"
                alt=""
              />
            </div>
            <div class="MainContent">
              <div class="LeftColumn">
                <div class="LeagueTableHeader">
                  <h1>STANDINGS</h1>
                  <h2>Regular Season</h2>
                </div>
                <div class="SubHeader">Round 18</div>
                <div class="Teams">
                  <div class="Row">
                    <div class="Position">1</div>
                    <div class="Badge">
                      <img src="/BasketballStyle/Images/Badge1.png" />
                    </div>
                    <div class="Team">
                      Fenerbache Beko
                      <br />
                      Istanbul
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Position">2</div>
                    <div class="Badge">
                      <img src="/BasketballStyle/Images/Badge2.png" />
                    </div>
                    <div class="Team">
                      Real
                      <br />
                      Madrid
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Position">3</div>
                    <div class="Badge">
                      <img src="/BasketballStyle/Images/Badge3.png" />
                    </div>
                    <div class="Team">
                      CSKA
                      <br />
                      Moscow
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Position">4</div>
                    <div class="Badge">
                      <img src="/BasketballStyle/Images/Badge4.png" />
                    </div>
                    <div class="Team">
                      Anadolu Efes
                      <br />
                      Instanbul
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Position">5</div>
                    <div class="Badge">
                      <img src="/BasketballStyle/Images/Badge5.png" />
                    </div>
                    <div class="Team">
                      Olympiacos
                      <br />
                      Piraeus
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Position">6</div>
                    <div class="Badge">
                      <img src="/BasketballStyle/Images/Badge6.png" />
                    </div>
                    <div class="Team">
                      FC Barcelona
                      <br />
                      Lassa
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Position">7</div>
                    <div class="Badge">
                      <img src="/BasketballStyle/Images/Badge7.png" />
                    </div>
                    <div class="Team">
                      FC Bayern
                      <br />
                      Munich
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Position">8</div>
                    <div class="Badge">
                      <img src="/BasketballStyle/Images/Badge8.png" />
                    </div>
                    <div class="Team">
                      Kirolbet Baskonia
                      <br />
                      Vitoria-Gasteiz
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Position">9</div>
                    <div class="Badge">
                      <img src="/BasketballStyle/Images/Badge9.png" />
                    </div>
                    <div class="Team">
                      AX Armani Exchange
                      <br />
                      Olimpia Milan
                    </div>
                  </div>
                </div>
              </div>
              <div class="RightColumn">
                <div class="LeagueTableHeader">
                  <img
                    class="ELLogo"
                    src="/BasketballStyle/Images/ELLogo.png"
                    alt=""
                  />
                  <img
                    class="Sponsor"
                    src="/BasketballStyle/Images/Sponsor.png"
                    alt=""
                  />
                </div>
                <div class="SubHeader">
                  <div class="Item">PLD</div>
                  <div class="Item">W</div>
                  <div class="Item">L</div>
                  <div class="Item">+/-</div>
                </div>
                <div class="Teams">
                  <div class="Row">
                    <div class="Played">18</div>
                    <div class="Won">16</div>
                    <div class="Lost">2</div>
                    <div class="PlusMinus">160</div>
                  </div>
                  <div class="Row">
                    <div class="Played">18</div>
                    <div class="Won">16</div>
                    <div class="Lost">2</div>
                    <div class="PlusMinus">160</div>
                  </div>
                  <div class="Row">
                    <div class="Played">18</div>
                    <div class="Won">16</div>
                    <div class="Lost">2</div>
                    <div class="PlusMinus">160</div>
                  </div>
                  <div class="Row">
                    <div class="Played">18</div>
                    <div class="Won">16</div>
                    <div class="Lost">2</div>
                    <div class="PlusMinus">160</div>
                  </div>
                  <div class="Row">
                    <div class="Played">18</div>
                    <div class="Won">16</div>
                    <div class="Lost">2</div>
                    <div class="PlusMinus">160</div>
                  </div>
                  <div class="Row">
                    <div class="Played">18</div>
                    <div class="Won">16</div>
                    <div class="Lost">2</div>
                    <div class="PlusMinus">160</div>
                  </div>
                  <div class="Row">
                    <div class="Played">18</div>
                    <div class="Won">16</div>
                    <div class="Lost">2</div>
                    <div class="PlusMinus">160</div>
                  </div>
                  <div class="Row">
                    <div class="Played">18</div>
                    <div class="Won">16</div>
                    <div class="Lost">2</div>
                    <div class="PlusMinus">160</div>
                  </div>
                  <div class="Row">
                    <div class="Played">18</div>
                    <div class="Won">16</div>
                    <div class="Lost">2</div>
                    <div class="PlusMinus">160</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
