import React, { Component } from "react";
import "./CookieContent.css";

export default class CookieContent extends Component {
  render() {
    return (
      <div className="CookieBackgroundContainer">
        {/* <div className="CookieBackground" /> */}
        <div className="CookieContent" id="Cookie">
          <div className="Content">
            <h1>Cookie Policy</h1>

            <img
              className="CookieMonster"
              src="https://www.seekpng.com/png/full/5-57859_download-sesame-street-cookie-monster-png.png"
              alt="Cookie Monster"
            />
          </div>
        </div>
      </div>
    );
  }
}
