import React, { Component } from "react";
import Header from "../WebsiteBuilder/Header/Header";
import Stages from "../WebsiteBuilder/Stages/Stages";
import Stage1 from "../WebsiteBuilder/Stage1/Stage1";
import Stage2 from "../WebsiteBuilder/Stage2/Stage2";

export default class WebsiteBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 1
    };
  }

  componentDidMount() {
    document.querySelector("body").style.backgroundColor = "#fff";
  }

  componentWillUnmount() {
    document.querySelector("body").style.backgroundColor = "";
  }

  onStageComplete(stage) {
    this.setState({
      stage: stage + 1
    });
  }

  render() {
    return (
      <div className="WebsiteBuilder">
        <Header />
        <Stages stage={this.state.stage} />
        {this.state.stage === 1 && (
          <Stage1 actionOnStageComplete={() => this.onStageComplete(1)} />
        )}

        {this.state.stage === 2 && (
          <Stage2 actionOnStageComplete={() => this.onStageComplete(2)} />
        )}
      </div>
    );
  }
}
