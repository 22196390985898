import React, { Component } from "react";
import "./Stage2.css";
export default class Stage1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gotDesign: null
    };
  }
  handleYesClicked() {
    this.setState({
      gotDesign: true
    });
  }
  handleNoClicked() {
    this.setState({
      gotDesign: false
    });
  }
  render() {
    return (
      <div className="Stage2">
        <h1>Design</h1>
        {this.state.gotDesign === null && (
          <div>
            <p>Do you have design files?</p>
            <div className="Buttons">
              <button onClick={() => this.handleYesClicked()}>Yes</button>
              <button
                className="Secondary"
                onClick={() => this.handleNoClicked()}
              >
                No
              </button>
            </div>
          </div>
        )}
        {this.state.gotDesign && <div>Upload files</div>}
      </div>
    );
  }
}
