import React from "react";
import styled from "styled-components";
import Brian from "../../Images/Brian.png";
import UI from "../../Images/UI.png";
import { device } from "../../Device";

const Main = styled.div`
  display: flex;
  max-height: 400px;
  background-color: #0b162f;
  padding-top: 95px;
  padding-bottom: 95px;
  justify-content: center;
  overflow: hidden;
  @media ${device.phone} {
    flex-direction: column;
    text-align: center;
  }
  @media ${device.tablet} {
    flex-direction: row;
    text-align: left;
  }
`;

const Quote = styled.div`
  display: flex;
  font-style: italic;
  color: #fff;
  line-height: 1.8em;
  font-size: 1.2em;
`;

const Profile = styled.div`
  display: flex;
  margin-top: 5%;
  @media ${device.phone} {
    flex-direction: column;
    align-items: center;
  }
  @media ${device.tablet} {
    flex-direction: row;
  }
`;

const Face = styled.img`
  border-radius: 50%;
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  overflow: hidden;
  border: 2px solid #fff;
  @media ${device.phone} {
    max-width: 120px;
    max-height: 120px;
  }
  @media ${device.tablet} {
    max-width: 80px;
    max-height: 80px;
  }
`;

const Half = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  @media ${device.phone} {
    width: 100%;
  }
  @media ${device.tablet} {
    width: 70%;
  }
`;

const Name = styled.div`
  display: flex;
  color: #dd1d44;
  font-size: 0.8em;
  font-weight: 600;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${device.phone} {
    align-items: center;
    margin-top: 2em;
  }
  @media ${device.tablet} {
    margin-left: 25px;
    align-items: flex-start;
    margin-top: 0;
  }
`;
const Position = styled.div`
  display: flex;
  color: #fff;
  font-size: 0.8em;
  line-height: 1.5em;
  margin-top: 1em;
`;
const Inner = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  @media ${device.phone} {
    width: 90%;
    flex-direction: column;
  }
  @media ${device.tablet} {
    width: 50%;
    flex-direction: row;
  }
`;
const Monitor = styled.img`
  width: 100%;
  height: auto;
  margin-left: auto;
  z-index: 1;
  @media ${device.phone} {
    display: none;
  }
  @media ${device.tablet} {
    display: block;
  }
`;

const Angle = styled.div`
  background-color: #dd1d44;
  width: 280px;
  height: 1500px;
  position: absolute;
  top: -284px;
  left: -80px;
  transform: rotate(26deg);
  @media ${device.phone} {
    display: none;
  }
  @media ${device.tablet} {
    display: block;
  }
`;
export default function ClientComment() {
  return (
    <Main>
      <Inner>
        <Half>
          <Quote>
            “HYPER Sports - Football gave us the flexibility we needed and is
            the most innovative part of IMG’s EFL operation.”
          </Quote>
          <Profile>
            <Face src={Brian} />
            <Details>
              <Name>BRIAN LEONARD</Name>
              <Position>
                HEAD OF ENGINEERING
                <br />
                PRODUCTION & WORKFLOWS AT IMG
              </Position>
            </Details>
          </Profile>
        </Half>
        <Half>
          <Angle />
          <Monitor src={UI} />
        </Half>
      </Inner>
    </Main>
  );
}
