import React, { Component } from "react";
import "./HoldingPage.css";
import Instagram from "../../Core/SVG/Instagram";
import Facebook from "../../Core/SVG/Facebook";
import Twitter from "../../Core/SVG/Twitter";
import Email from "../../Core/SVG/Email";
export default class HoldingPage extends Component {
  constructor(props) {
    super(props);
    var a = window.moment();
    var b = window.moment([2019, 2, 20, 9, 0]);

    let duration = window.moment.duration(b - a);
    this.state = {
      months: duration.months(),
      days: Math.floor(duration.asDays()),
      hours: duration.asHours(),
      minutes: duration.minutes(),
      seconds: duration.seconds()
    };
  }
  componentDidMount() {
    setTimeout(() => {
      document.getElementById("1").classList.add("Active");
    }, 100);
    setTimeout(() => {
      document.getElementById("1").style.display = "none";
      document.getElementById("2").classList.add("Active");
    }, 500);
    setTimeout(() => {
      document.getElementById("2").style.display = "none";
      document.getElementById("3").classList.add("Active");
    }, 1000);
    setTimeout(() => {
      document.getElementById("3").style.display = "none";
      document.getElementById("4").classList.add("Active");
    }, 1500);
    setTimeout(() => {
      document.getElementById("4").style.display = "none";
      document.getElementById("5").classList.add("Active");
    }, 2000);
    setTimeout(() => {
      document.getElementById("5").style.display = "none";
      document.getElementById("6").classList.add("Active");
    }, 2500);
    setTimeout(() => {
      document.getElementById("LaunchText").classList.add("Active");
    }, 3200);
    setTimeout(() => {
      document.getElementById("Clock").classList.add("Active");
    }, 4000);
    setTimeout(() => {
      document.getElementById("Link1").classList.add("Active");
      document.getElementById("Link2").classList.add("Active");
      document.getElementById("Link3").classList.add("Active");
      document.getElementById("Link4").classList.add("Active");
    }, 4000);
    setInterval(() => this.updateTime(), 500);
  }

  componentWillMount() {
    document.querySelector("body").style.backgroundColor = "#0b162f";
  }

  updateTime() {
    var a = window.moment();
    var b = window.moment([2019, 2, 20, 9, 0]);

    let duration = window.moment.duration(b - a);
    this.setState({
      months: duration.months(),
      days: Math.floor(duration.asDays()),
      hours: Math.floor(duration.asHours()),
      minutes: duration.minutes(),
      seconds: duration.seconds()
    });
  }
  render() {
    return (
      <div>
        <div className="White" />
        <div className="Blue" />
        <div className="HoldingPage">
          <div className="Launch">
            <img id="1" className="Logo" src="Images/H.png" alt="Hyper Logo" />
            <img id="2" className="Logo" src="Images/Y.png" alt="Hyper Logo" />
            <img id="3" className="Logo" src="Images/P.png" alt="Hyper Logo" />
            <img id="4" className="Logo" src="Images/E.png" alt="Hyper Logo" />
            <img id="5" className="Logo" src="Images/R.png" alt="Hyper Logo" />
            <img
              id="6"
              className="Logo Main"
              src="Images/HyperLogo.png"
              alt="Hyper Logo"
            />
          </div>
          <div className="Social" id="Social">
            <div className="SocialLink" id="Link1">
              <a href="https://www.instagram.com/hyper_studios_social">
                <Instagram />
              </a>
            </div>
            <div className="SocialLink" id="Link2">
              <a href="https://www.facebook.com/hyperstudiossocial">
                <Facebook />
              </a>
            </div>
            <div className="SocialLink" id="Link3">
              <a href="https://twitter.com/StudiosHyper">
                <Twitter />
              </a>
            </div>
            <div className="SocialLink" id="Link4">
              <a href="mailto:info@hyperstudios.co.uk">
                <Email />
              </a>
            </div>
          </div>

          <div className="LaunchText" id="LaunchText">
            Launching soon..
          </div>

          <div className="Clock" id="Clock">
            <div className="TimeElement">
              <div className="Value">{this.state.hours}</div>
              <div className="Category">
                {this.state.hours === 1 ? "Hour" : "Hours"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
