import React, { Component } from "react";

import "./App.css";

import HoldingPage from "./Components/HoldingPage/HoldingPage2";

import { BrowserRouter as Router, Route } from "react-router-dom";
import Main from "./Views/Main";
import Main2 from "./Views/Main2";
import Main3 from "./Views/Main3";
import Main4 from "./Views/Main4";
import Privacy from "./Views/Privacy";
import About from "./Views/About";
import CookiePolicy from "./Views/CookiePolicy";
import TermsOfUse from "./Views/TermsOfUse";
import ScrollToTop from "./Scroll";
import Create from "./Views/Create";
import WebsiteBuilder from "./Views/WebsiteBuilder";
import Design from "./Views/Design";
import Statement from "./Views/Statement";
import RenderDocs from "./Views/RenderDocs";
import NewSite from "./NewSite/NewSite";
import NewSite2 from "./NewSite/NewSite2";

class App extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop>
          <div className="App">
            <div>
              <Route path="/" exact component={NewSite2} />
              <Route path="/Main" exact component={Main3} />
              <Route path="/Main2" exact component={Main2} />
              <Route path="/Main3" exact component={Main} />
              <Route path="/Main4" exact component={Main4} />
              <Route path="/Privacy" exact component={Privacy} />
              <Route path="/About" exact component={About} />
              <Route path="/Cookie" exact component={CookiePolicy} />
              <Route path="/Terms" exact component={TermsOfUse} />
              <Route path="/Create" exact component={Create} />
              <Route path="/Build" exact component={WebsiteBuilder} />
              <Route path="/Design" exact component={Design} />
              <Route path="/Statement" exact component={Statement} />
              <Route path="/RenderDocs" exact component={RenderDocs} />
              <Route path="/v2" exact component={NewSite} />
              <Route path="/v3" exact component={NewSite2} />
            </div>
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
