import React, { Component } from "react";
import Header from "../Components/Header/Header";
import Hero from "../Components/Hero/Hero";

import AboutContent from "../Components/AboutContent/AboutContent";
import Footer from "../Components/Footer/Footer";

export default class About extends Component {
  render() {
    return (
      <div>
        <Header colour={"3"} showLinks={true} />
        <Hero colour={"3"} text="Who we are.." />
        <AboutContent />
        <Footer altColour={true} />
      </div>
    );
  }
}
