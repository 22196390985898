import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./HeroButtons.css";
import anime from "animejs";

export default class HeroButtons extends Component {
  componentDidMount() {
    this.header = document.getElementById("Header");
  }

  handleClick(id) {
    let top =
      this.offset(document.getElementById(id)).top -
      this.header.offsetHeight -
      20;

    let scrollCoords = {
      y: window.pageYOffset
    };
    anime({
      targets: scrollCoords,
      y: top,
      duration: 200,
      easing: "easeInOutQuad",
      update: () => window.scroll(0, scrollCoords.y)
    });
  }

  offset(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  render() {
    return (
      <div className="HeroButtons">
        <div className="Buttons">
          <button onClick={() => this.handleClick("Contact")}>
            Get in touch
          </button>
        </div>
      </div>
    );
  }
}
