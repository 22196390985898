import React, { Component } from "react";
import Page1 from "../Components/DesignPortal/Page1";
import Page2 from "../Components/DesignPortal/Page2";
import Page3 from "../Components/DesignPortal/Page3";
import FixedNav from "../Components/DesignPortal/FixedNav";

export default class Design extends Component {
  render() {
    return (
      <div>
        <FixedNav />
        <Page1 />
        <Page2 />
        <Page3 />
      </div>
    );
  }
}
