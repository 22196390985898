import React, { Component } from "react";
import Footer from "../Components/Footer/Footer";
import Hero from "../Components/Hero/Hero";
import Header from "../Components/Header/Header";
import StatementContent from "../Components/StatementContent/StatementContent";

export default class Statement extends Component {
  render() {
    return (
      <div>
        <Header colour={"3"} showLinks={false} />
        <Hero colour={"3"} text="Modern Slavery Statement 2020" />
        <StatementContent />
        <Footer altColour={true} route={"Statement"} />
      </div>
    );
  }
}
