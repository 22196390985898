import React, { Component } from "react";
import MainContent from "../Components/MainContent/MainContent";
import SocialContent from "../Components/SocialContent/SocialContent";
import AboutContent from "../Components/AboutContent/AboutContent";
import ContactContent from "../Components/ContactConent/ContactContent";
import Footer from "../Components/Footer/Footer";
import Hero from "../Components/Hero/Hero";
import Header from "../Components/Header/Header";
import HeroButtons from "../Components/Hero/HeroButtons2";

export default class Main extends Component {
  render() {
    return (
      <div>
        <Header colour={"3"} showLinks={true} />
        <Hero
          colour={"3"}
          text="Need help with any creative broadcast solutions..."
        />
        <HeroButtons />
        <MainContent />
        <SocialContent altColour={false} />
        <div className="LowerSection Social">
          <div className="Content">
            <AboutContent />
            <ContactContent />
          </div>
        </div>
        <Footer altColour={true} />
      </div>
    );
  }
}
