import React, { Component } from "react";
import "./Hero.css";
import anime from "animejs";

export default class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 3000
    };
  }
  componentWillMount() {
    setTimeout(() => {
      let appHeight = document.querySelector("body").clientHeight;
      this.setState({
        height: appHeight
      });
      anime({
        targets: document.getElementById("Polygons"),
        height: appHeight + "px",
        duration: 2000
      });
    }, 500);
  }

  render() {
    let colour1 = "#e95c81";
    let colour2 = "#db0a41";
    let colour3 = "#f5f3f7";
    let backColour = "#fff";
    let textColour = "";
    let transform = "rotate(45 100 50) translate(0 0)";

    if (this.props.colour === "2") {
      colour1 = "#202e4c";
      colour2 = "#0b162f";
    }

    if (this.props.colour === "3" || this.props.colour === "4") {
      colour3 = "#202e4c";
      backColour = "#0b162f";
      textColour = "#f5d24b";
      transform = "rotate(45 100 50) translate(1.22 0)";
    }

    this.text2 = this.props.text2 || "";

    return (
      <div className="Hero">
        <div className="Background" style={{ backgroundColor: backColour }} />
        <div
          className="Polygons"
          id="Polygons"
          style={{
            height: `${this.state.height}px`
          }}
        >
          <svg
            viewBox="0 0 10 10"
            preserveAspectRatio="xMinYMin slice"
            className="PolygonLeft"
          >
            <polygon fill={colour3} points="-5,-5 15,15 -5,15 " />
          </svg>
          {this.props.colour !== "4" && (
            <svg
              viewBox="0 0 10 10"
              preserveAspectRatio="xMidYMin meet"
              className="PolygonRight"
            >
              <svg x="-15%" y="-10%" style={{ overflow: "visible" }}>
                <rect
                  className="PolygonInner"
                  width="10000%"
                  height="10000%"
                  fill={colour1}
                  transform={transform}
                />
                <rect
                  width="10000%"
                  height="10000%"
                  fill={colour2}
                  transform="rotate(45 100 50) translate(1.25 0)"
                />
              </svg>
            </svg>
          )}
        </div>

        <div className="HeroContent">
          <h1 className="Text" style={{ color: textColour }}>
            {this.props.text}
            <br />
            {this.text2}
          </h1>
        </div>
      </div>
    );
  }
}
