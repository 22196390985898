import React from "react";
import styled from "styled-components";
import DesignIcon from "../../Images/DESIGN.png";
import LiveIcon from "../../Images/LIVE.png";
import SportsIcon from "../../Images/SPORTS.png";
import SpaceIcon from "../../Images/SPACE.png";
import DriveIcon from "../../Images/DRIVE.png";
import TouchIcon from "../../Images/TOUCH.png";
import DesignScreen from "../../Images/DesignScreen.png";
import FootballScreen from "../../Images/FootballScreen.png";
import LiveScreen from "../../Images/LiveScreen.png";
import SpaceScreen from "../../Images/SpaceScreen.png";
import anime from "animejs";
import { device } from "../../Device";

const Main = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  padding-top: 3%;
  padding-bottom: 3%;
  flex-direction: column;
  @media ${device.phone} {
    max-width: 100%;
  }
  @media ${device.tablet} {
    max-width: 90%;
  }
  @media ${device.laptopL} {
    max-width: 50%;
  }
`;
const InnerContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  @media ${device.phone} {
    flex-direction: column;
    align-items: center;
  }
  @media ${device.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 1px -2px 20px 2px rgb(0 0 0 / 15%);

  align-items: center;
  padding: 1em;
  margin-bottom: 2em;
  justify-content: space-around;
  box-sizing: border-box;
  transition: transform 0.2s ease-in;
  background-color: #fff;

  @media ${device.phone} {
    width: 90%;
  }
  @media ${device.tablet} {
    width: 28%;
  }
  :hover {
    transform: scaleX(1.05) scaleY(1.05);
  }
`;
const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
`;

const Logo = styled.img`
  width: 100%;
  border-radius: 4px;
  margin-top: 0.5em;
`;

const LogoComingSoon = styled.div`
  min-width: 100%;
  height: 126.5px;
  border-radius: 4px;
  margin-top: 0.5em;
  background-color: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #717171;
`;

const P = styled.p`
  display: flex;
  font-size: 0.8em;
  /* text-align: center; */
  color: #0b162f;
  line-height: 1.5em;
`;

const Button = styled.div`
  display: flex;
  background-color: ${(props) => (props.red ? "#dd1d44" : "#0b162f")};
  color: #fff;
  padding: 0.5em 1em 0.5em 1em;
  margin-right: 1em;
  font-size: 0.6em;
  align-items: center;
  justify-content: center;
  min-width: 118px;
  min-height: 30px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in;
  :hover {
    background-color: #dd1d44;
  }
`;
const Content = styled.div`
  min-height: 138px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const H2 = styled.h2`
  color: #0b162f;
  font-size: 1.8em;
  font-weight: 600;
`;

const Icon = styled.img`
  height: 30px;
`;
export default function Products({ showTitle }) {
  function handleClick(id) {
    let top =
      offset(document.getElementById(id)).top -
      document.getElementById("header").offsetHeight -
      0;

    let scrollCoords = {
      y: window.pageYOffset,
    };
    anime({
      targets: scrollCoords,
      y: top,
      duration: 500,
      easing: "easeInOutQuad",
      update: () => window.scroll(0, scrollCoords.y),
    });
  }
  function offset(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  return (
    <Main>
      {showTitle && <H2>PRODUCTS</H2>}
      <InnerContent>
        <Card>
          <Title>
            <Icon src={SportsIcon} />
          </Title>
          <Logo src={FootballScreen}></Logo>
          <Content>
            <P>
              A suite of dedicated sports solutions including Football, Rugby
              and Basketball.​
            </P>
            <P>
              Data driven or manually logged. HYPER sports gets data to air
              faster.
            </P>
          </Content>
          <Button
            onClick={() => {
              handleClick("contact");
            }}
          >
            FIND OUT MORE
          </Button>
        </Card>
        <Card>
          <Title>
            <Icon src={DesignIcon} />
          </Title>
          <Logo src={DesignScreen}></Logo>
          <Content>
            <P>
              HYPER Engines Graphic Design app,​ with fully key frameable nodes​
              and unlimited transitions.
            </P>
          </Content>
          <Button
            onClick={() => {
              handleClick("contact");
            }}
          >
            FIND OUT MORE
          </Button>
        </Card>
        <Card>
          <Title>
            <Icon src={LiveIcon} />
          </Title>
          <Logo src={LiveScreen}></Logo>
          <Content>
            <P>
              A page-based real-time graphics playout​ system, with multiple
              data widgets available.​
            </P>
          </Content>
          <Button
            onClick={() => {
              handleClick("contact");
            }}
          >
            FIND OUT MORE
          </Button>
        </Card>
        <Card>
          <Title>
            <Icon src={SpaceIcon} />
          </Title>
          <Logo src={SpaceScreen}></Logo>
          <Content>
            <P>
              ​​Cloud graphic rendering, automation and data solutions.​ ​
              Create post-production and digital graphics from anywhere.​
            </P>
          </Content>
          <Button
            onClick={() => {
              handleClick("contact");
            }}
          >
            FIND OUT MORE
          </Button>
        </Card>
        <Card>
          <Title>
            <Icon src={DriveIcon} />
          </Title>
          <LogoComingSoon>Coming Soon!</LogoComingSoon>
          <Content>
            <P>​An automated clipping and​ logging system. ​​</P>
          </Content>
          <Button
            onClick={() => {
              handleClick("contact");
            }}
          >
            FIND OUT MORE
          </Button>
        </Card>
        <Card>
          <Title>
            <Icon src={TouchIcon} />
          </Title>
          <LogoComingSoon>Coming Soon!</LogoComingSoon>
          <Content>
            <P>
              ​Entry level Touchscreen and​ Telestration solution with​ embedded
              clip store.​ ​
            </P>
          </Content>
          <Button
            onClick={() => {
              handleClick("contact");
            }}
          >
            FIND OUT MORE
          </Button>
        </Card>
      </InnerContent>
    </Main>
  );
}
