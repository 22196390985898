import React from "react";
import styled from "styled-components";
import anime from "animejs";
import { Link } from "react-router-dom";
import { device } from "../../Device";

const Main = styled.div`
  background-color: #0b162f;
  padding: 5%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;
const HyperLogo = styled.img`
  height: 50px;
`;
const Content = styled.div`
  display: flex;

  color: #fff;

  justify-content: flex-end;
  @media ${device.phone} {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    width: 100%;
  }
  @media ${device.tablet} {
    flex-direction: row;
    align-items: flex-start;
    margin-left: auto;
    width: 50%;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.phone} {
    margin-left: 0;
    align-items: center;
  }
  @media ${device.tablet} {
    margin-left: 50px;
    align-items: flex-start;
  }
`;
const Title = styled.h2`
  font-size: 0.8em;
  font-weight: 600;
`;
const DivLink = styled.div`
  font-weight: 100;
  font-size: 0.7em;
  line-height: 2em;
  cursor: pointer;
  transition: color 0.3s ease;
  :hover {
    color: #dd1d44;
  }
`;
const RouterLink = styled(Link)`
  font-weight: 100;
  font-size: 0.7em;
  line-height: 2em;
  cursor: pointer;
  transition: color 0.3s ease;
  :hover {
    color: #dd1d44;
  }
`;

const Copyright = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 0px;
  color: #fff;
  font-size: 0.7em;
  padding-bottom: 0.5em;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  @media ${device.phone} {
    flex-direction: column;
    align-items: center;
  }
  @media ${device.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;
export default function Footer() {
  function handleClick(id) {
    let top =
      offset(document.getElementById(id)).top -
      document.getElementById("header").offsetHeight -
      0;

    let scrollCoords = {
      y: window.pageYOffset,
    };
    anime({
      targets: scrollCoords,
      y: top,
      duration: 500,
      easing: "easeInOutQuad",
      update: () => window.scroll(0, scrollCoords.y),
    });
  }
  function offset(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  return (
    <Main>
      <Row>
        <HyperLogo src="Images/HyperLogo.png" alt="Company Logo" />
        <Content>
          <Column>
            <Title>Company</Title>
            <DivLink onClick={() => handleClick("about")}>About</DivLink>
          </Column>
          <Column>
            <Title>Follow Us</Title>
            <DivLink>
              <a href="https://www.instagram.com/hyper_studios_social">
                Instagram
              </a>
            </DivLink>
            <DivLink>
              <a href="https://twitter.com/StudiosHyper">Twitter</a>
            </DivLink>
            <DivLink>
              <a href="https://www.facebook.com/hyperstudiossocial">Facebook</a>
            </DivLink>
          </Column>
          <Column>
            <Title>Contact Us</Title>
            <DivLink>
              <a href="mailto:info@hyperstudios.co.uk">
                info@hyperstudios.co.uk
              </a>
            </DivLink>
          </Column>
          <Column>
            <Title>Legal</Title>
            <RouterLink to="/Privacy">Privacy Policy</RouterLink>
            <RouterLink to="/Cookie">Cookie Policy</RouterLink>
            <RouterLink to="/Statement">Modern Slavery Statement</RouterLink>
          </Column>
        </Content>
      </Row>
      <Copyright>© 2020 Hyper Studios Limited, All Rights Reserved.</Copyright>
    </Main>
  );
}
