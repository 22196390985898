import React, { Component } from "react";
import "./Header.css";
import Instagram from "../../Core/SVG/Instagram";
import Facebook from "../../Core/SVG/Facebook";
import Twitter from "../../Core/SVG/Twitter";
import anime from "animejs";
import Email from "../../Core/SVG/Email";

export default class HeaderCreate extends Component {
  componentDidMount() {
    this.header = document.getElementById("Header");
    window.addEventListener("scroll", this.scroll.bind(this));
  }

  componentWillMount() {
    window.removeEventListener("scroll", this.scroll.bind(this));
  }

  scroll(e) {
    if (e.target.scrollingElement.scrollTop > this.header.offsetTop) {
      this.header.classList.add("Fixed");
      if (this.props.colour === "3" || this.props.colour === "4") {
        this.header.classList.add("Dark");
      }
    } else {
      this.header.classList.remove("Fixed");
      this.header.classList.remove("Dark");
    }
  }

  handleClick(id) {
    let top =
      this.offset(document.getElementById(id)).top -
      this.header.offsetHeight -
      20;

    let scrollCoords = {
      y: window.pageYOffset
    };
    anime({
      targets: scrollCoords,
      y: top,
      duration: 200,
      easing: "easeInOutQuad",
      update: () => window.scroll(0, scrollCoords.y)
    });
  }

  offset(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  render() {
    let textColour = "";
    let showLinks = this.props.showLinks;

    if (this.props.colour === "3" || this.props.colour === "4") {
      textColour = "#fff";
    }
    return (
      <div className="Header" id="Header" style={{ color: textColour }}>
        <div className="HeaderContainer">
          <a className="LogoLink" href="/">
            <img
              className="Logo"
              src="Images/HyperLogo.png"
              alt="Company Logo"
            />
          </a>
          {showLinks === true && (
            <div className="PageLinks">
              <div className="Link" onClick={() => this.handleClick("Pricing")}>
                Pricing
              </div>
              <div
                className="Link"
                onClick={() => this.handleClick("Features")}
              >
                Features
              </div>
              <div
                className="Link"
                onClick={() => this.handleClick("Questions")}
              >
                Questions
              </div>
              <div className="Link" onClick={() => this.handleClick("Contact")}>
                Contact
              </div>
            </div>
          )}

          <div className="Social">
            <div className="SocialLink">
              <a href="https://www.instagram.com/hyper_studios_social">
                <Instagram />
              </a>
            </div>
            <div className="SocialLink">
              <a href="https://www.facebook.com/hyperstudiossocial">
                <Facebook />
              </a>
            </div>
            <div className="SocialLink">
              <a href="https://twitter.com/StudiosHyper">
                <Twitter />
              </a>
            </div>
            <div className="SocialLink" id="Link4">
              <a href="mailto:info@hyperstudios.co.uk">
                <Email />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
