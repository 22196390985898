import React, { Component } from "react";
import "./TwitterBlock.css";
export default class TwitterBlock extends Component {
  render() {
    return (
      <div className="TwitterBlock">
        <a href="https://twitter.com/StudiosHyper">
          <div className="TwitterAvatar">
            <div
              className="TwitterProfileImage"
              style={{
                backgroundImage:
                  "url(https://scontent-lhr3-1.cdninstagram.com/vp/9a56d77e866e9eb804b00bc79f038f2a/5CF6D6D1/t51.2885-15/e35/s150x150/49801280_594266391020266_8604738760462716686_n.jpg?_nc_ht=scontent-lhr3-1.cdninstagram.com)"
              }}
            />
            @StudiosHyper
          </div>
        </a>
        <div className="TwitterComment">{this.props.comment}</div>
      </div>
    );
  }
}
