import React, { Component } from "react";
import "./MainContent2.css";
import SubContent from "./SubContent/SubContent";
import PricePlanItem from "../PricePlan/PricePlanItem";

export default class MainContent extends Component {
  render() {
    return (
      <div className="MainContentBackgroundContainer">
        <div className="MainContentBackground" />
        <div className="MainContent2" id="Services">
          <div className="Content">
            <PricePlanItem
              title="Graphics"
              content={
                "A full range of graphics services for Outside Broadcast, post production, Digital, SaaS products, platform integrations, data management systems and bespoke graphics software development. Hyper has a solution to meet all your graphics needs."
              }
              page={""}
              showButton={false}
              showMore={false}
            />
            <PricePlanItem
              title="Workflow"
              content={
                "Whether its digital, scheduling, Rushes Management, archive, highlights or edit, Hyper can help with your workflow needs."
              }
              page={""}
              showButton={false}
              showMore={false}
            />
            <PricePlanItem
              title="Creative"
              content={
                "Our creative team understands data and has strong experience in designing broadcast graphics for sport.  We’re confident that we can work with you and surpass expectation."
              }
              page={""}
              showButton={false}
              showMore={false}
            />
            <PricePlanItem
              title="Consultancy"
              content={
                "Whether it’s a new project or existing production, Hyper can provide consultancy, systems analysis and project management services to help ensure that you have the correct solution. "
              }
              page={""}
              showButton={false}
              showMore={false}
            />
            <PricePlanItem
              title="AR & VR"
              content={
                "Our agnostic approach to platform and tracking technology ensures that we specify the best system for your project. From full PBR, photo realistic, Virtual sets to Chroma keyed tied to pitch solutions, Hyper will work with you, to deliver the best solution."
              }
              page={""}
              showButton={false}
              showMore={false}
            />
            <PricePlanItem
              title="Integrations"
              content={
                "Hyper currently has integrations with ChyronHego, Vizrt, Ross Video, RT Software, Avid and Brainstorm. Tight budget? Try Hyper Engine, our own, in house solution."
              }
              page={""}
              showButton={false}
              showMore={false}
            />
          </div>
        </div>
      </div>
    );
  }
}
