import React, { Component } from "react";
import "./Stages.css";

export default class Stages extends Component {
  render() {
    return (
      <div className="Stages">
        <div className="Stage">
          <div
            className={
              this.props.stage > 1 ? "Circle Complete" : "Circle Active"
            }
          >
            1
          </div>
          <div className="Title">Details</div>
        </div>
        <div className="Line">
          <div
            className="Progress"
            style={{ width: this.props.stage > 1 ? "100%" : "10%" }}
          />
        </div>

        <div className="Stage">
          <div
            className={
              this.props.stage > 2
                ? "Circle Complete"
                : this.props.stage === 2
                ? "Circle Active"
                : "Circle"
            }
          >
            2
          </div>
          <div className="Title">Design</div>
        </div>
        <div className="Line" />
        <div className="Stage">
          <div
            className={
              this.props.stage > 3
                ? "Circle Complete"
                : this.props.stage === 3
                ? "Circle Active"
                : "Circle"
            }
          >
            3
          </div>
          <div className="Title">Inspiration</div>
        </div>
      </div>
    );
  }
}
