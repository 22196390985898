import React, { Component } from "react";
import "./MainContent.css";
import SubContent from "./SubContent/SubContent";

export default class MainContent extends Component {
  render() {
    return (
      <div className="MainContentBackgroundContainer">
        <div className="MainContentBackground" />
        <div className="MainContent" id="Services">
          <div className="Content">
            <SubContent
              title="Broadcast Services"
              content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porttitor tellus a egestas accumsan. "
            />
            <SubContent
              title="Graphics"
              content="A full range of graphics services for Outside Broadcast, post production, Digital, SaaS products, platform integrations."
            />
            <SubContent
              title="Creative Services"
              content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porttitor tellus a egestas accumsan. "
            />
            <SubContent
              title="Data Integration"
              content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porttitor tellus a egestas accumsan. "
            />
            <SubContent
              title="Workflow"
              content="Whether its digital, scheduling, Rushes Management, archive, highlights or edit, Hyper can help with your workflow needs."
            />
            <SubContent
              title="Creative"
              content="Our creative team understands data and has strong experience in designing broadcast graphics for sport.  We’re confident that we can work with you and surpass expectation."
            />
            {/*<div className="Section">
              <h1 id="Friends">Just a few of our friends..</h1>
              <div className="FriendLogos">
                <div className="FriendLogo">
                  <img src="Images/skysports.png" alt="sky sports logo" />
                </div>
                <div className="FriendLogo">
                  <img src="Images/img.png" alt="IMG Studios logo" />
                </div>
                <div className="FriendLogo">
                  <img src="Images/neverno.png" alt="NeverNo logo" />
                </div>
              </div>
            </div>
    */}
          </div>
        </div>
      </div>
    );
  }
}
