import React from "react";
import styled from "styled-components";
import vizrt from "../../Images/vizrt.png";
import chyron from "../../Images/chyron.png";
import ross from "../../Images/ross.png";
import avid from "../../Images/avid.png";
import brainstorm from "../../Images/brainstorm.png";
import rt from "../../Images/rt.png";
import { device } from "../../Device";

const Main = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
  padding-top: 3%;
  padding-bottom: 3%;
  flex-direction: column;
  @media ${device.phone} {
    max-width: 100%;
  }
  @media ${device.tablet} {
    max-width: 90%;
  }
  @media ${device.laptopL} {
    max-width: 50%;
  }
`;
const InnerContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  @media ${device.phone} {
    flex-direction: column;
    align-items: center;
  }
  @media ${device.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 1px -2px 20px 2px rgb(0 0 0 / 15%);
  align-items: center;
  padding: 1em;
  margin-bottom: 2em;
  justify-content: space-around;
  box-sizing: border-box;
  transition: transform 0.2s ease-in;
  background-color: #fff;
  @media ${device.phone} {
    width: 90%;
  }
  @media ${device.tablet} {
    width: 28%;
  }
  :hover {
    transform: scaleX(1.05) scaleY(1.05);
  }
`;
const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  display: none;
`;

const Logo = styled.img`
  width: auto;
  height: 50px;
`;

const P = styled.p`
  display: flex;
  font-size: 0.8em;
  color: #0b162f;
  line-height: 1.5em;
`;

const Button = styled.div`
  display: flex;
  background-color: ${(props) => (props.red ? "#dd1d44" : "#0b162f")};
  color: #fff;
  padding: 0.5em 1em 0.5em 1em;
  margin-right: 1em;
  font-size: 0.6em;
  align-items: center;
  justify-content: center;
  min-width: 118px;
  min-height: 30px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in;
  :hover {
    background-color: #dd1d44;
  }
`;
const Content = styled.div`
  min-height: 138px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export default function GraphicsIntegrations({ showTitle }) {
  return (
    <Main>
      <InnerContent>
        <Card>
          <Logo src={vizrt}></Logo>
          <Title>VIZRT</Title>
          <Content>
            <P>
              Vizrt is a Norwegian company that creates content production,
              management and distribution tools for the digital media industry​.
            </P>
          </Content>
          <a target="_blank" href="https://www.vizrt.com/">
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={chyron}></Logo>
          <Title>CHYRON</Title>
          <Content>
            <P>
              Chyron specialises in broadcast graphics creation, playout, &
              real-time data visualization for live TV, news, weather & sports
              production.​
            </P>
          </Content>
          <a target="_blank" href="https://chyron.com​​">
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={ross}></Logo>
          <Title>ROSS VIDEO​</Title>
          <Content>
            <P>
              XPression is Ross Video’s premier line of real-time graphics
              systems, VR rendering engines and workflow tools.​
            </P>
          </Content>
          <a
            target="_blank"
            href="https://www.rossvideo.com/products-services/acquisition-production/cg-graphics-systems/xpression"
          >
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={rt}></Logo>
          <Title>RT SOFTWARE​</Title>
          <Content>
            <P>
              RT Software is an award-winning provider of innovative and
              industry leading broadcast graphics solutions​.
            </P>
          </Content>
          <a target="_blank" href="https://rtsw.co.uk">
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={avid}></Logo>
          <Title>AVID</Title>
          <Content>
            <P>
              Maestro Graphics. ... Powerful and flexible, Maestro is tightly
              integrated into the Avid product portfolio.​
            </P>
          </Content>
          <a target="_blank" href="https://www.avid.com/maestro-graphics">
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={brainstorm}></Logo>
          <Title>BRAINSTORM</Title>
          <Content>
            <P>
              Brainstorm is a specialist company dedicated to provide
              industry-leading real-time 3D graphics and virtual set solutions
              since 1993.​
            </P>
          </Content>
          <a target="_blank" href="https://www.brainstorm3d.com">
            <Button>MORE INFO</Button>
          </a>
        </Card>
      </InnerContent>
    </Main>
  );
}
