import React, { Component } from "react";
import "./PricePlan.css";
import PricePlanItem from "./PricePlanItem";

export default class PricePlan extends Component {
  render() {
    return (
      <div className="PricePlanBackgroundContainer">
        <div className="PricePlanBackground" />
        <div className="PricePlan" id="Pricing">
          <div className="Content">
            <PricePlanItem
              title="Already have a design"
              content={
                "If you have a design we can convert it to a working website for you. Photoshop, PNG, PDF etc.. any image file we will convert"
              }
              page={"£120"}
              showButton={true}
            />
            <PricePlanItem
              title="Want help with design"
              content={
                "Don't have a design?.. Don't worry we can help. Use our online portal to create moodboards, manage the project and work with us to design your dream website."
              }
              page={"£180"}
              showButton={true}
            />
            <PricePlanItem
              title="Fully managed"
              content={
                "Take the hassle out of running a website. With this option we will host and manage the server & domain for your brand new website and make any small changes throughout the year."
              }
              monthly={"£40"}
              showButton={true}
            />
            <PricePlanItem
              title="3rd Party Integration"
              content={
                "Want social media or a CMS or even maybe build an online store we can integrate with all the big online services"
              }
              price={"Contact Us"}
              showButton={true}
            />
          </div>
          <div className="Bespoke">
            Want something a little more bespoke...{" "}
            <button className="Quote">Get a Quote</button>
          </div>
        </div>
      </div>
    );
  }
}
