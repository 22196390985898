import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../Device";

const Main = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  @media ${device.phone} {
    flex-direction: column;
  }
  @media ${device.tablet} {
    flex-direction: row;
  }
`;
const Red = styled.div`
  display: flex;

  background-color: #dd1d44;
  width: 100%;

  z-index: 0;
  color: #fff;
  justify-content: center;
  @media ${device.phone} {
    position: relative;
    height: 100%;
  }
  @media ${device.tablet} {
    position: absolute;
    height: 300px;
  }
`;

const H1 = styled.h1`
  font-size: 3em;
`;

const Content = styled.div`
  display: flex;
  padding-top: 50px;
  color: #fff;
  @media ${device.phone} {
    width: 100%;
  }
  @media ${device.tablet} {
    width: 50%;
  }
`;
const InnerContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;

  @media ${device.phone} {
    display: ${(props) => (props.red ? "flex" : "none")};
    width: 100%;
    align-items: center;
    text-align: center;
  }
  @media ${device.tablet} {
    display: ${(props) => (props.red ? "none" : "flex")};
    width: 50%;
    align-items: flex-start;
    text-align: left;
  }
`;

const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;

  @media ${device.phone} {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  @media ${device.tablet} {
    width: 50%;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const P = styled.p`
  max-width: 90%;
  line-height: 1.5em;
  font-size: 0.8em;
`;

const Form = styled.div`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  padding: 1.5em;
  margin-bottom: 100px;
  box-shadow: 1px -2px 20px 2px rgb(0 0 0 / 15%);
  @media ${device.phone} {
    width: 80%;
  }
  @media ${device.tablet} {
    width: 80%;
  }
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
`;
const Label = styled.div`
  color: #dd1d44;
  font-size: 0.6em;
  font-weight: 600;
`;
const Input = styled.input`
  border: none;
  border-bottom: 2px solid #dcdcdc;
  width: 100%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  box-sizing: border-box;
  transition: all 0.4s ease;
  :focus {
    outline: none;
    border-bottom: 2px solid #dd1d44;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 0.7; /* Firefox */
  }
`;

const TextArea = styled.textarea`
  border: none;
  border: 2px solid #dcdcdc;
  border-radius: 4px;
  width: 100%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  box-sizing: border-box;
  transition: all 0.4s ease;
  resize: none;

  :focus {
    outline: none;
    border-bottom: 2px solid #dd1d44;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 0.7; /* Firefox */
  }
`;

const Privacy = styled.div`
  color: grey;
  font-size: 0.7em;
  display: flex;
  align-items: center;
`;

const Bold = styled.span`
  font-weight: 600;
  color: #dd1d44;
  margin-left: 3px;
`;

const Button = styled.div`
  margin-top: 3em;
  display: flex;
  background-color: ${(props) => (props.red ? "#dd1d44" : "#fff")};
  color: ${(props) => (props.red ? "#fff" : "#dd1d44")};
  padding: 0.5em 1em 0.5em 1em;
  margin-right: 1em;
  font-size: 0.6em;
  align-items: center;
  justify-content: center;
  min-width: 118px;
  min-height: 30px;
  font-weight: 600;
  cursor: pointer;
  @media ${device.phone} {
    flex-direction: column;
  }
  @media ${device.tablet} {
    flex-direction: row;
  }
`;
const Details = styled.div`
  display: flex;

  flex-direction: column;

  font-size: 0.8em;
  line-height: 2em;
  @media ${device.phone} {
    margin-top: 10px;
    color: #fff;
  }
  @media ${device.tablet} {
    margin-top: 100px;
    color: #0b162f;
  }
`;
const Section = styled.div`
  margin-bottom: 2em;
  flex-direction: column;
  display: flex;
`;

const Address = styled.div`
  line-height: 1.5em;
  display: flex;
  flex-direction: column;
`;

const Error = styled.div`
  color: #fb1067;
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: 600;
  margin-top: 2em;
`;

const Confirm = styled.div`
  color: #48cea2;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 600;
  margin-top: 2em;
  text-align: center;
`;

const RouterLink = styled(Link)`
  cursor: pointer;
`;

export default function Contact() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [company, setCompany] = useState();
  const [message, setMessage] = useState();
  const [accept, setAccept] = useState(false);
  const [error, setError] = useState();
  const [sent, setSent] = useState(false);
  function sendMessage() {
    if (!name || name == "") {
      setError("Please enter your name");
    } else if (!email || email == "") {
      setError("Please enter your email");
    } else if (!message || message == "") {
      setError("Please enter a message");
    } else if (!accept) {
      setError("Please accept the privacy policy to send a message");
    } else {
      setError();
      fetch("/new/contact", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ name, email, phone, company, message }),
      })
        .then((res) => {
          if (res.ok) {
            setSent(true);
            return setError();
          }
          throw Error;
        })
        .catch((err) => {
          setError("Couldn't send message please try again");
        });
    }
  }
  return (
    <Main id="contact">
      <Red>
        <Column red={true}>
          <H1>Contact Us</H1>
          <P>
            Let us know if you are interested in our products and someone from
            our helpful team will get back to you
          </P>
          <Details>
            <Section>
              <Bold>Email</Bold>
              <b>info@hyperstudios.co.uk</b>
            </Section>
            <Section>
              <Bold>Head Office</Bold>
              <b>+44 (0) 7752 186482</b>
              <Address>
                Unit 4
                <br />
                Twigworth Court Business Centre
                <br />
                Twigworth
                <br />
                Gloucestershire
                <br />
                GL2 9PG. UK
              </Address>
            </Section>
          </Details>
        </Column>
      </Red>
      <Content>
        <Column red={false}>
          <H1>Contact Us</H1>
          <P>
            Let us know if you are interested in our products and some from our
            helpful team will get back to you
          </P>
          <Details>
            <Section>
              <Bold>Email</Bold>
              <b>info@hyperstudios.co.uk</b>
            </Section>
            <Section>
              <Bold>Head Office</Bold>
              <b>+44 (0) 7752 186482</b>
              <Address>
                Unit 4
                <br />
                Twigworth Court Business Centre
                <br />
                Twigworth
                <br />
                Gloucestershire
                <br />
                GL2 9PG. UK
              </Address>
            </Section>
          </Details>
        </Column>
        <ColumnRight>
          <Form>
            <Data>
              <Label>NAME *</Label>
              <Input
                placeholder="Name..."
                value={name}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
              />
            </Data>
            <Data>
              <Label>EMAIL ADDRESS *</Label>
              <Input
                placeholder="Email..."
                value={email}
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                }}
              />
            </Data>
            <Data>
              <Label>TELEPHONE</Label>
              <Input
                placeholder="Telephone..."
                value={phone}
                onChange={(e) => {
                  setPhone(e.currentTarget.value);
                }}
              />
            </Data>
            <Data>
              <Label>COMPANY</Label>
              <Input
                placeholder="Company..."
                value={company}
                onChange={(e) => {
                  setCompany(e.currentTarget.value);
                }}
              />
            </Data>
            {/* <Data>
              <Label>COUNTRY</Label>
              <Input placeholder="Please select..." />
            </Data>
            <Data>
              <Label>I AM INTERSTED IN</Label>
              <Input placeholder="Nothing Selected" />
            </Data> */}
            <Data>
              <Label>YOUR MESSAGE *</Label>
              <TextArea
                rows="7"
                placeholder="Say hi..."
                value={message}
                onChange={(e) => {
                  setMessage(e.currentTarget.value);
                }}
              />
            </Data>
            <Privacy>
              <input
                type="checkbox"
                checked={accept}
                onChange={(e) => {
                  setAccept(e.currentTarget.checked);
                }}
              />{" "}
              I agree to hyper{" "}
              <a target="_blank" href="/Privacy">
                <Bold>privacy policy</Bold>
              </a>
            </Privacy>
            {error && <Error>{error}</Error>}
            {!sent && (
              <Button
                red
                onClick={() => {
                  sendMessage();
                }}
              >
                SEND YOUR ENQUIRY
              </Button>
            )}
            {sent && (
              <Confirm>
                Thank you for your message.
                <br />
                We will be in touch soon.
              </Confirm>
            )}
          </Form>
        </ColumnRight>
      </Content>
    </Main>
  );
}
