import React, { Component } from "react";
import { Link } from "react-router-dom";
import anime from "animejs";
import "./Footer.css";

export default class Footer extends Component {
  componentDidMount() {
    this.header = document.getElementById("Header");
  }

  handleClick(id) {
    let top =
      this.offset(document.getElementById(id)).top -
      this.header.offsetHeight -
      20;

    let scrollCoords = {
      y: window.pageYOffset,
    };
    console.log(top);
    anime({
      targets: scrollCoords,
      y: top,
      duration: 200,
      easing: "easeInOutQuad",
      update: () => window.scroll(0, scrollCoords.y),
    });
  }

  offset(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  render() {
    let colour2 = "#db0a41";

    if (this.props.altColour) {
      colour2 = "#0b162f";
    }

    return (
      <div className="Footer" style={{ backgroundColor: colour2 }}>
        <div className="Container">
          <div className="Logo">
            <img src="Images/HyperLogo.png" alt="Hyper Logo" />
          </div>
          <div className="Lists">
            <div className="List">
              <h3>Company</h3>
              <div>
                <Link
                  to="/"
                  className="AboutFooter"
                  onClick={() => {
                    if (
                      this.props.route === "PRIVACY" ||
                      this.props.route === "COOKIE"
                    ) {
                      setTimeout(() => {
                        this.handleClick("About");
                      }, 700);
                    } else {
                      this.handleClick("About");
                    }
                  }}
                >
                  About
                </Link>
              </div>
            </div>
            <div className="List">
              <h3>Follow Us</h3>
              <div>
                <a href="https://www.facebook.com/hyperstudiossocial">
                  Facebook
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/hyper_studios_social">
                  Instagram
                </a>
              </div>
              <div>
                <a href="https://twitter.com/StudiosHyper">Twitter</a>
              </div>
            </div>
            <div className="List">
              <h3>Contact Us</h3>
              <div>
                <a href="mailto:info@hyperstudios.co.uk<">
                  info@hyperstudios.co.uk
                </a>
              </div>
              <div>
                <a href="tel:+447340154881">tel: +44 (0) 7340 154881</a>
              </div>
            </div>
            <div className="List">
              <h3>Legal</h3>
              <div>
                <Link to="/Privacy">Privacy Policy</Link>
              </div>
              <div>
                <Link to="/Cookie">Cookie Policy</Link>
              </div>
              <div>
                <Link to="/Statement">Modern Slavery Statement</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="Copyright">
          © 2019 Hyper Studios Limited, All Rights Reserved.
        </div>
      </div>
    );
  }
}
