import React, { Component } from "react";
import "./FixedNav.css";

export default class FixedNav extends Component {
  render() {
    return (
      <div className="FixedNav">
        <div className="Item">1</div>
        <div className="Item Active">2</div>
        <div className="Item">3</div>
        <div className="Item">4</div>
        <div className="Item">5</div>
        <div className="Item">6</div>
      </div>
    );
  }
}
