import React, { Component } from "react";
import "./Questions.css";
import ContactContent from "../ContactConent/ContactContent";

export default class Questions extends Component {
  render() {
    return (
      <div className="Questions">
        <h1 id="Questions">Got a question?</h1>
        <div className="Content">
          <h2>Some of the common ones..</h2>
          <div className="List">
            <div className="Question">
              <h2>What design files do you accept?</h2>
              <p>
                We pretty much accept any files. PSD, PNG, AI, JPG, PDF, we will
                do our best with whatever you give us but for the best results
                we need the indivual assets (logos, pictures etc)
              </p>
            </div>
            <div className="Question">
              <h2>What if I don't like the design?</h2>
              <p>
                We will continue to try our best to come up with a design you
                like. If you don't like our designs then you don't pay, simple
                as that.
              </p>
            </div>
            <div className="Question">
              <h2>How do I pay?</h2>
              <p>At the moment our preferred method is via PayPal.</p>
            </div>
            <div className="Question">
              <h2>When do I pay?</h2>
              <p>
                No money is needed upfront. We will charge once certain
                milestones are complete, such as when a page is finished.
              </p>
            </div>
          </div>
          <ContactContent />
        </div>
      </div>
    );
  }
}
