import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./HeroButtons.css";

export default class HeroButtons extends Component {
  render() {
    return (
      <div className="HeroButtons">
        <div className="Buttons">
          <Link to="/Build">
            <button>Start New Project</button>
          </Link>
          <button className="Secondary">Message Us</button>
        </div>
        <p>Get started for free. No credit card required.</p>
      </div>
    );
  }
}
