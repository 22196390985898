import React from "react";
import styled from "styled-components";
import SimplyLive from "../../Images/simply Live.png";
import Vmix from "../../Images/vmix.png";
import Wirecast from "../../Images/wirecast.png";
import OBS from "../../Images/obs.png";
import EVS from "../../Images/evs.png";
import mosys from "../../Images/mosys.png";
import { device } from "../../Device";

const Main = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
  padding-top: 3%;
  padding-bottom: 3%;
  flex-direction: column;
  @media ${device.phone} {
    max-width: 100%;
  }
  @media ${device.tablet} {
    max-width: 90%;
  }
  @media ${device.laptopL} {
    max-width: 50%;
  }
`;
const InnerContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  @media ${device.phone} {
    flex-direction: column;
    align-items: center;
  }
  @media ${device.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 1px -2px 20px 2px rgb(0 0 0 / 15%);

  align-items: center;
  padding: 1em;
  margin-bottom: 2em;
  justify-content: space-around;
  box-sizing: border-box;
  transition: transform 0.2s ease-in;
  background-color: #fff;
  @media ${device.phone} {
    width: 90%;
  }
  @media ${device.tablet} {
    width: 28%;
  }
  :hover {
    transform: scaleX(1.05) scaleY(1.05);
  }
`;
const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  display: none;
`;

const Logo = styled.img`
  width: auto;
  height: 50px;
`;

const P = styled.p`
  display: flex;
  font-size: 0.8em;
  line-height: 1.5em;
  color: #0b162f;
`;

const Button = styled.div`
  display: flex;
  background-color: ${(props) => (props.red ? "#dd1d44" : "#0b162f")};
  color: #fff;
  padding: 0.5em 1em 0.5em 1em;
  margin-right: 1em;
  font-size: 0.6em;
  align-items: center;
  justify-content: center;
  min-width: 118px;
  min-height: 30px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in;
  :hover {
    background-color: #dd1d44;
  }
`;
const Content = styled.div`
  min-height: 138px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export default function BroadcastIntegrations({ showTitle }) {
  return (
    <Main>
      <InnerContent>
        <Card>
          <Logo src={SimplyLive}></Logo>
          <Title>SIMPLY LIVE</Title>
          <Content>
            <P>
              ViBox is a software-driven live production platform running on a
              common back end, using rock solid commodity hardware.​
            </P>
          </Content>
          <a target="_blank" href="https://www.simplylive.tv">
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={Vmix}></Logo>
          <Title>VMIX</Title>
          <Content>
            <P>
              Create professional live video productions using vMix.​ Live
              Stream to your favourite streaming providers.​
            </P>
          </Content>
          <a target="_blank" href="https://www.vmix.com">
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={Wirecast}></Logo>
          <Title>WIRECAST</Title>
          <Content>
            <P>Wirecast lets you share your passions with the world – live.​</P>
          </Content>
          <a
            target="_blank"
            href="https://www.telestream.net/wirecast/overview.htm"
          >
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={OBS}></Logo>
          <Title>OBS</Title>
          <Content>
            <P>
              Free and open source software for video recording and live
              streaming..​
            </P>
          </Content>
          <a target="_blank" href="https://obsproject.com">
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={EVS}></Logo>
          <Title>EVS</Title>
          <Content>
            <P>
              EVS is globally recognized as the leader in live video technology
              for broadcast and new media productions.​
            </P>
          </Content>
          <a target="_blank" href="https://www.evs.com/en">
            <Button>MORE INFO</Button>
          </a>
        </Card>
        <Card>
          <Logo src={mosys}></Logo>
          <Title>MO-SYS</Title>
          <Content>
            <P>
              Global leaders in creating original and highly sophisticated
              camera technology products for film and broadcast, empowering
              high-quality AR, VR and virtual production​.​
            </P>
          </Content>
          <a target="_blank" href="https://www.mo-sys.com">
            <Button>MORE INFO</Button>
          </a>
        </Card>
      </InnerContent>
    </Main>
  );
}
