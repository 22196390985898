import React, { useEffect } from "react";
import Header from "./Components/Header/Header";
import Hero from "./Components/Hero/Hero";
import styled from "styled-components";
import About from "./Components/About/About";
import ClientComment from "./Components/ClientComment/ClientComment";
import Products from "./Components/Products/Products";
import Footer from "./Components/Footer/Footer";
import ProductInfo from "./Components/Products/ProductInfo";
import Services from "./Components/Services/Services";
import ServicesInfo from "./Components/Services/ServicesInfo";
import Contact from "./Components/Contact/Contact";
import SportsIntegrations from "./Components/Integrations/SportsIntegrations";
import IntegrationHeader from "./Components/Integrations/IntegrationHeader";
import SocialIntegrations from "./Components/Integrations/SocialIntegrations";
import GraphicsIntegrations from "./Components/Integrations/GraphicsIntegration";
import BroadcastIntegrations from "./Components/Integrations/BroadcastIntegrations";
import Clients from "./Components/Clients/Clients";
const Main = styled.div`
  font-family: "Montserrat", sans-serif;
  background-color: #fff;
  width: 100vw;
  overflow-anchor: none;
`;
export default function NewSite2() {
  return (
    <Main id="main">
      <Header />
      <Hero />
      <About />
      <ClientComment />
      <ProductInfo red={true} />
      <Products showTitle={false} />
      <ServicesInfo red={false} showTitle={true} />
      <Services showTitle={false} />
      <IntegrationHeader title="SPORTS DATA INTEGRATIONS" />
      <SportsIntegrations />
      <IntegrationHeader title="SOCIAL/AUDIENCE ENGAGEMENT" />
      <SocialIntegrations />
      <IntegrationHeader title="BROADCAST GRAPHICS SYSTEMS" />
      <GraphicsIntegrations />
      <IntegrationHeader title="BROADCAST SYSTEMS" />
      <BroadcastIntegrations />
      <Clients />
      <Contact />
      <Footer />
    </Main>
  );
}
