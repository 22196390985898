import React, { Component } from "react";
import "./Features.css";

export default class Features extends Component {
  render() {
    return (
      <div className="Features">
        <h1 id="Features">Features</h1>
        <div className="Content">
          <div className="List">
            <div className="Feature">
              <div className="Icon">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/W3C_valid.svg/2000px-W3C_valid.svg.png"
                  alt="W3C Logo"
                />
              </div>
              <h2>W3C Valid</h2>
              <p>
                All website we deliver will be built using 100% validated markup
                passed by the World Wide Web Consortium (W3C).
              </p>
            </div>
            <div className="Feature">
              <div className="Icon">
                <img
                  src=" https://cdn0.iconfinder.com/data/icons/HTML5/512/HTML_Logo.png"
                  alt="HTML 5 Logo"
                />
              </div>

              <h2>HTML5 & CSS3</h2>
              <p>
                Using the latest technologies and standards we try and make your
                new website compatible with all the modern desktop and mobile
                browsers.
              </p>
            </div>
            <div className="Feature">
              <div className="Icon">
                <img
                  src=" https://cdn1.iconfinder.com/data/icons/browser-user-interface-1/240/responsive-devices-ipad-iphone-512.png"
                  alt="Responsive Icon"
                />
              </div>

              <h2>Fully Responsive</h2>
              <p>
                We will make sure your website looks good and is responsive on
                any device. As the device changes size so should your website.
              </p>
            </div>
            <div className="Feature">
              <div className="Icon">
                <img
                  src="https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png"
                  alt="React.js Logo"
                />
              </div>

              <h2>React</h2>
              <p>
                Your site will be built with the most popular frontend framework
                today.
              </p>
            </div>
            <div className="Feature">
              <div className="Icon">
                <img
                  src="https://nodejs.org/static/images/logos/nodejs-new-pantone-black.png"
                  alt="Node.js Logo"
                />
              </div>

              <h2>Node.js</h2>
              <p>
                If you need advanced features or choose our hosting option your
                website will be built upon Node.js as its backend.
              </p>
            </div>
            <div className="Feature">
              <div className="Icon">
                <img
                  src=" https://cdn0.iconfinder.com/data/icons/HTML5/512/HTML_Logo.png"
                  alt="HTML 5 Logo"
                />
              </div>

              <h2>HTML5 & CSS3</h2>
              <p>
                Using the latest technologies and standards we try and make your
                new website compatible with all the modern desktop and mobile
                browsers.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
