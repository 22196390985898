import React, { Component } from "react";
import "./PageMain.css";
import "./Page1.css";

import ChevronDown from "../../Core/SVG/ChevronDown";

export default class Page1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 3000
    };
  }
  componentWillMount() {
    setTimeout(() => {
      let appHeight = document.querySelector("body").clientHeight;
      this.setState({
        height: appHeight
      });
    }, 500);
  }

  render() {
    let colour1 = "#e95c81";
    let colour2 = "#db0a41";
    let colour3 = "#202e4c";
    let backColour = "#0b162f";
    let textColour = "#f5d24b";
    let transform = "rotate(45 100 50) translate(1.22 0)";

    return (
      <div className="Page1">
        <div className="Background" style={{ backgroundColor: backColour }} />
        <div
          className="Polygons"
          id="Polygons"
          style={{
            height: `${this.state.height}px`
          }}
        >
          <svg
            viewBox="0 0 10 10"
            preserveAspectRatio="xMinYMin slice"
            className="PolygonLeft"
          >
            <polygon fill={colour3} points="-5,-5 15,15 -5,15 " />
          </svg>
        </div>

        <div className="HeroContent">
          <div className="Logos">
            <img
              className="Logo"
              src="Images/HyperLogo.png"
              alt="Company Logo"
            />
          </div>
          <h1 className="Text" style={{ color: textColour }}>
            IMG Euroleague Graphics Proposal
          </h1>
        </div>
        <div className="Scroll bounce">
          <ChevronDown />
        </div>
      </div>
    );
  }
}
